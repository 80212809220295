import { put, call, takeLatest } from 'redux-saga/effects';
import axios from '../../../config/axios';
import {
  fetchDataSuccess,
  FETCH_DATA,
  fetchDataFail,
  FETCH_GRN_INVOICE_DATA,
  fetchGrnInvoiceDataSuccess,
  fetchGrnInvoiceDataFail,
  FETCH_DATA_WITH_GRN_NO,
} from '../../reducers/History/grnHistoryReducer';

function* fetchGrnHistory({ selectedFromDate, selectedToDate, supplierId, shopId }) {
  try {
    const response = yield call(
      axios.get,
      `grn?fromDate=${selectedFromDate}&toDate=${selectedToDate}&supplierId=${supplierId}&shopId=${shopId}`,
    );
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchGrnHistorySaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchGrnHistory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchGrnDataHistory({ grnNumber }) {
  try {
    const response = yield call(axios.get, `grn/${grnNumber}`);
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.grnHistory));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchGrnDataHistorySaga() {
  try {
    yield takeLatest(FETCH_DATA_WITH_GRN_NO, fetchGrnDataHistory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchGrnHistoryInvoice({ supplierId }) {
  try {
    const response = yield call(axios.get, `grn/${supplierId}`);
    if (response.data.success === true) {
      yield put(fetchGrnInvoiceDataSuccess(response.data));
    } else {
      yield put(fetchGrnInvoiceDataFail());
    }
  } catch (error) {
    yield put(fetchGrnInvoiceDataFail(error));
  }
}

export function* fetchGrnHistoryInvoiceSaga() {
  try {
    yield takeLatest(FETCH_GRN_INVOICE_DATA, fetchGrnHistoryInvoice);
  } catch (error) {
    yield put(fetchGrnInvoiceDataFail(error));
  }
}

export const SET_ITEM_CODE = 'genius/reports/stockMovement/SET_ITEM_CODE';
export const SET_SHOP_ID = 'genius/reports/stockMovement/SET_SHOP_ID';

export const FETCH_DATA = 'genius/reports/stockMovement/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/stockMovement/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/stockMovement/FETCH_DATA_FAIL';
export const RESET_DATA = 'genius/reports/stockMovement/RESET_DATA';

const initialState = {
  loading: false,
  stockMovementData: [],
  itemCode: '',
  shopId: '0',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_CODE:
      return { ...state, loading: false, itemCode: action.itemCode };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, stockMovementData: action.stockMovementData, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, stockMovementData: [] };
    case RESET_DATA:
      return { ...state, itemCode: initialState.itemCode, shopId: initialState.shopId };
    default:
      return state;
  }
};
export default reducer;

export const fetchData = (itemCode, shopId) => {
  return { type: FETCH_DATA, itemCode, shopId };
};
export const fetchDataSuccess = (stockMovementData) => {
  return { type: FETCH_DATA_SUCCESS, stockMovementData };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const setBarcode = (itemCode) => {
  return { type: SET_ITEM_CODE, itemCode };
};
export const resetStockMovement = () => {
  return { type: RESET_DATA };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};

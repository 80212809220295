const fmToUnicode = (text) => {
  if (!text || text === null) {
    return null;
  }
  text = text.replace(/'/g, 'Ã');
  text = text.replace(/"/g, '»');
  text = text.replace(/›/g, 'ශ්‍රී');
  text = text.replace(/ff;%/g, 'ත්‍රෛ');
  text = text.replace(/ffY/g, 'ශෛ');
  text = text.replace(/ffp/g, 'චෛ');
  text = text.replace(/ffc/g, 'ජෛ');
  text = text.replace(/ffk/g, 'නෛ');
  text = text.replace(/ffl/g, 'කෛ');
  text = text.replace(/ffu/g, 'මෛ');
  text = text.replace(/ffm/g, 'පෛ');
  text = text.replace(/ffo/g, 'දෛ');
  text = text.replace(/ff;/g, 'තෛ');
  text = text.replace(/ffO/g, 'ධෛ');
  text = text.replace(/ffj/g, 'වෛ');
  text = text.replace(/fm%!/g, 'ප්‍රෞ');
  text = text.replace(/fIHda/g, 'ෂ්‍යෝ');
  text = text.replace(/fPHda/g, 'ඡ්‍යෝ');
  text = text.replace(/fVHda/g, 'ඪ්‍යෝ');
  text = text.replace(/f>Hda/g, 'ඝ්‍යෝ');
  text = text.replace(/fLHda/g, 'ඛ්‍යෝ');
  text = text.replace(/f<Hda/g, 'ළ්‍යෝ');
  text = text.replace(/fMHda/g, 'ඵ්‍යෝ');
  text = text.replace(/fGHda/g, 'ඨ්‍යෝ');
  text = text.replace(/fYHda/g, 'ශ්‍යෝ');
  text = text.replace(/fCIHda/g, 'ක්‍ෂ්‍යෝ');
  text = text.replace(/fnHda/g, 'බ්‍යෝ');
  text = text.replace(/fpHda/g, 'ච්‍යෝ');
  text = text.replace(/fvHda/g, 'ඩ්‍යෝ');
  text = text.replace(/f\*Hda/g, 'ෆ්‍යෝ');
  text = text.replace(/f\.Hda/g, 'ග්‍යෝ');
  text = text.replace(/fcHda/g, 'ජ්‍යෝ');
  text = text.replace(/flHda/g, 'ක්‍යෝ');
  text = text.replace(/f,Hda/g, 'ල්‍යෝ');
  text = text.replace(/fuHda/g, 'ම්‍යෝ');
  text = text.replace(/fkHda/g, 'න්‍යෝ');
  text = text.replace(/fmHda/g, 'ප්‍යෝ');
  text = text.replace(/foHda/g, 'ද්‍යෝ');
  text = text.replace(/fiHda/g, 'ස්‍යෝ');
  text = text.replace(/fgHda/g, 'ට්‍යෝ');
  text = text.replace(/fjHda/g, 'ව්‍යෝ');
  text = text.replace(/f;Hda/g, 'ත්‍යෝ');
  text = text.replace(/fNHda/g, 'භ්‍යෝ');
  text = text.replace(/fOHda/g, 'ධ්‍යෝ');
  text = text.replace(/f:Hda/g, 'ථ්‍යෝ');
  text = text.replace(/fIHd/g, 'ෂ්‍යො');
  text = text.replace(/fYHd/g, 'ශ්‍යො');
  text = text.replace(/fLHd/g, 'ඛ්‍යො');
  text = text.replace(/fCIHd/g, 'ක්‍ෂ්‍යො');
  text = text.replace(/fnHd/g, 'බ්‍යො');
  text = text.replace(/fjHd/g, 'ව්‍යො');
  text = text.replace(/fvHd/g, 'ඩ්‍යො');
  text = text.replace(/f\*Hd/g, 'ෆ්‍යො');
  text = text.replace(/f\.Hd/g, 'ග්‍යො');
  text = text.replace(/fcHd/g, 'ජ්‍යො');
  text = text.replace(/flHd/g, 'ක්‍යො');
  text = text.replace(/fuHd/g, 'ම්‍යො');
  text = text.replace(/fmHd/g, 'ප්‍යො');
  text = text.replace(/foHd/g, 'ද්‍යො');
  text = text.replace(/fiHd/g, 'ස්‍යො');
  text = text.replace(/fgHd/g, 'ට්‍යො');
  text = text.replace(/fjHd/g, 'ව්‍යො');
  text = text.replace(/f;Hd/g, 'ත්‍යො');
  text = text.replace(/fNHd/g, 'භ්‍යො');
  text = text.replace(/fOHd/g, 'ධ්‍යො');
  text = text.replace(/f:Hd/g, 'ථ්‍යො');
  text = text.replace(/fIH/g, 'ෂ්‍යෙ');
  text = text.replace(/fPH/g, 'ඡ්‍යෙ');
  text = text.replace(/f<H/g, 'ළ්‍යෙ');
  text = text.replace(/fKH/g, 'ණ්‍යෙ');
  text = text.replace(/fpH/g, 'ච්‍යෙ');
  text = text.replace(/f,H/g, 'ල්‍යෙ');
  text = text.replace(/fkH/g, 'න්‍යෙ');
  text = text.replace(/fYH/g, 'ශ්‍යෙ');
  text = text.replace(/fLH/g, 'ඛ්‍යෙ');
  text = text.replace(/fCIH/g, 'ක්‍ෂ්‍යෙ');
  text = text.replace(/fnH/g, 'බ්‍යෙ');
  text = text.replace(/fvH/g, 'ඩ්‍යෙ');
  text = text.replace(/f\*H/g, 'ෆ්‍යෙ');
  text = text.replace(/f\.H/g, 'ග්‍යෙ');
  text = text.replace(/fcH/g, 'ජ්‍යෙ');
  text = text.replace(/flH/g, 'ක්‍යෙ');
  text = text.replace(/fuH/g, 'ම්‍යෙ');
  text = text.replace(/fmH/g, 'ප්‍යෙ');
  text = text.replace(/foH/g, 'ද්‍යෙ');
  text = text.replace(/fiH/g, 'ස්‍යෙ');
  text = text.replace(/fgH/g, 'ට්‍යෙ');
  text = text.replace(/fjH/g, 'ව්‍යෙ');
  text = text.replace(/f;H/g, 'ත්‍යෙ');
  text = text.replace(/fNH/g, 'භ්‍යෙ');
  text = text.replace(/fOH/g, 'ධ්‍යෙ');
  text = text.replace(/f:H/g, 'ථ්‍යෙ');
  text = text.replace(/hH_/g, 'ර්ය');
  text = text.replace(/fI%da/g, 'ෂ්‍රෝ');
  text = text.replace(/f>%da/g, '‍ඝ්‍රෝ');
  text = text.replace(/fY%da/g, 'ශ්‍රෝ');
  text = text.replace(/fCI%da/g, 'ක්‍ෂ්‍රෝ');
  text = text.replace(/fn%da/g, 'බ්‍රෝ');
  text = text.replace(/fv%da/g, 'ඩ්‍රෝ');
  text = text.replace(/f\*%da/g, 'ෆ්‍රෝ');
  text = text.replace(/f\.%da/g, 'ග්‍රෝ');
  text = text.replace(/fl%da/g, 'ක්‍රෝ');
  text = text.replace(/fm%da/g, 'ප්‍රෝ');
  text = text.replace(/føda/g, 'ද්‍රෝ');
  text = text.replace(/fi%da/g, 'ස්‍රෝ');
  text = text.replace(/fg%da/g, 'ට්‍රෝ');
  text = text.replace(/f\;%da/g, 'ත්‍රෝ');
  text = text.replace(/fY%d/g, 'ශ්‍රො');
  text = text.replace(/fv%d/g, 'ඩ්‍රො');
  text = text.replace(/f\*%d/g, 'ෆ්‍රො');
  text = text.replace(/f\.%d/g, 'ග්‍රො');
  text = text.replace(/fl%d/g, 'ක්‍රො');
  text = text.replace(/fm%d/g, 'ප්‍රො');
  text = text.replace(/fød/g, 'ද්‍රො');
  text = text.replace(/fi%d/g, 'ස්‍රො');
  text = text.replace(/fg%d/g, 'ට්‍රො');
  text = text.replace(/f\;%d/g, 'ත්‍රො');
  text = text.replace(/%a/g, 'a%');
  text = text.replace(/fYa%/g, 'ශ්‍රේ');
  text = text.replace(/fí%/g, 'බ්‍රේ');
  text = text.replace(/fâ%/g, 'ඩ්‍රේ');
  text = text.replace(/f\*%a/g, 'ෆ්‍රේ');
  text = text.replace(/f\.%a/g, 'ග්‍රේ');
  text = text.replace(/fl%a/g, 'ක්‍රේ');
  text = text.replace(/fm%a/g, 'ප්‍රේ');
  text = text.replace(/føa/g, 'ද්‍රේ');
  text = text.replace(/fia%/g, 'ස්‍රේ');
  text = text.replace(/f\;a%/g, 'ත්‍රේ');
  text = text.replace(/fè%/g, 'ධ්‍රේ');
  text = text.replace(/fI%/g, 'ෂ්‍රෙ');
  text = text.replace(/fY%/g, 'ශ්‍රෙ');
  text = text.replace(/fn%/g, 'බ්‍රෙ');
  text = text.replace(/f\*%/g, 'ෆ්‍රෙ');
  text = text.replace(/f\.%/g, 'ග්‍රෙ');
  text = text.replace(/fl%/g, 'ක්‍ර'); // Update from ක්‍රෙ
  text = text.replace(/fm%/g, 'ප්‍රෙ');
  text = text.replace(/fø/g, 'ද්‍රෙ');
  text = text.replace(/fi%/g, 'ස්‍රෙ');
  text = text.replace(/f\;%/g, 'ත්‍රෙ');
  text = text.replace(/fN%/g, 'භ්‍රෙ');
  text = text.replace(/fO%/g, 'ධ්‍රෙ');
  text = text.replace(/fI!/g, 'ෂෞ');
  text = text.replace(/fP!/g, 'ඡෞ');
  text = text.replace(/fY!/g, 'ශෞ');
  text = text.replace(/fn!/g, 'බෞ');
  text = text.replace(/fp!/g, 'චෞ');
  text = text.replace(/fv!/g, 'ඩෞ');
  text = text.replace(/f\*!/g, 'ෆෞ');
  text = text.replace(/f\.!/g, 'ගෞ');
  text = text.replace(/fc!/g, 'ජෞ');
  text = text.replace(/fl!/g, 'කෞ');
  text = text.replace(/f,!/g, 'ලෞ');
  text = text.replace(/fu!/g, 'මෞ');
  text = text.replace(/fk!/g, 'නෞ');
  text = text.replace(/fm!/g, 'පෞ');
  text = text.replace(/fo!/g, 'දෞ');
  text = text.replace(/fr!/g, 'රෞ');
  text = text.replace(/fi!/g, 'සෞ');
  text = text.replace(/fg!/g, 'ටෞ');
  text = text.replace(/f\;!/g, 'තෞ');
  text = text.replace(/fN!/g, 'භෞ');
  text = text.replace(/f\[!/g, 'ඤෞ');
  text = text.replace(/fIda/g, 'ෂෝ');
  text = text.replace(/fUda/g, 'ඹෝ');
  text = text.replace(/fPda/g, 'ඡෝ');
  text = text.replace(/fVda/g, 'ඪෝ');
  text = text.replace(/f>da/g, 'ඝෝ');
  text = text.replace(/fLda/g, 'ඛෝ');
  text = text.replace(/f<da/g, 'ළෝ');
  text = text.replace(/f`yda/g, 'ඟෝ');
  text = text.replace(/fKda/g, 'ණෝ');
  text = text.replace(/fMda/g, 'ඵෝ');
  text = text.replace(/fGda/g, 'ඨෝ');
  text = text.replace(/f~da/g, 'ඬෝ');
  text = text.replace(/fYda/g, 'ශෝ');
  text = text.replace(/f\\{da/g, 'ඥෝ');
  text = text.replace(/f\da/g, 'ඳෝ');
  text = text.replace(/fIda/g, 'ෂෝ');
  text = text.replace(/fnda/g, 'බෝ');
  text = text.replace(/fpda/g, 'චෝ');
  text = text.replace(/fvda/g, 'ඩෝ');
  text = text.replace(/f\*da/g, 'ෆෝ');
  text = text.replace(/f\.da/g, 'ගෝ');
  text = text.replace(/fyda/g, 'හෝ');
  text = text.replace(/fcda/g, 'ජෝ');
  text = text.replace(/flda/g, 'කෝ');
  text = text.replace(/f,da/g, 'ලෝ');
  text = text.replace(/fuda/g, 'මෝ');
  text = text.replace(/fkda/g, 'නෝ');
  text = text.replace(/fmda/g, 'පෝ');
  text = text.replace(/foda/g, 'දෝ');
  text = text.replace(/frda/g, 'රෝ');
  text = text.replace(/fida/g, 'සෝ');
  text = text.replace(/fgda/g, 'ටෝ');
  text = text.replace(/fjda/g, 'වෝ');
  text = text.replace(/f\;da/g, 'තෝ');
  text = text.replace(/fNda/g, 'භෝ');
  text = text.replace(/fhda/g, 'යෝ');
  text = text.replace(/f\[da/g, 'ඤෝ');
  text = text.replace(/fOda/g, 'ධෝ');
  text = text.replace(/f\:da/g, 'ථෝ');
  text = text.replace(/fId/g, 'ෂො');
  text = text.replace(/fUd/g, 'ඹො');
  text = text.replace(/fPd/g, 'ඡො');
  text = text.replace(/fVd/g, 'ඪො');
  text = text.replace(/f>d/g, 'ඝො');
  text = text.replace(/fLd/g, 'ඛො');
  text = text.replace(/f<d/g, 'ළො');
  text = text.replace(/f`yd/g, 'ඟො');
  text = text.replace(/fKd/g, 'ණො');
  text = text.replace(/fMd/g, 'ඵො');
  text = text.replace(/fGd/g, 'ඨො');
  text = text.replace(/f`Vd/g, 'ඬො');
  text = text.replace(/fYd/g, 'ශො');
  text = text.replace(/f\\{d/g, 'ඥො');
  text = text.replace(/f\d/g, 'ඳො');
  text = text.replace(/fId/g, 'ෂො');
  text = text.replace(/fnd/g, 'බො');
  text = text.replace(/fpd/g, 'චො');
  text = text.replace(/fvd/g, 'ඩො');
  text = text.replace(/f\*d/g, 'ෆො');
  text = text.replace(/f\.d/g, 'ගො');
  text = text.replace(/fyd/g, 'හො');
  text = text.replace(/fcd/g, 'ජො');
  text = text.replace(/fld/g, 'කො');
  text = text.replace(/f,d/g, 'ලො');
  text = text.replace(/fud/g, 'මො');
  text = text.replace(/fkd/g, 'නො');
  text = text.replace(/fmd/g, 'පො');
  text = text.replace(/fod/g, 'දො');
  text = text.replace(/frd/g, 'රො');
  text = text.replace(/fid/g, 'සො');
  text = text.replace(/fgd/g, 'ටො');
  text = text.replace(/fjd/g, 'වො');
  text = text.replace(/f\;d/g, 'තො');
  text = text.replace(/fNd/g, 'භො');
  text = text.replace(/fhd/g, 'යො');
  text = text.replace(/f\[d/g, 'ඤො');
  text = text.replace(/fOd/g, 'ධො');
  text = text.replace(/f\:d/g, 'ථො');
  text = text.replace(/fIa/g, 'ෂේ');
  text = text.replace(/fò/g, 'ඹේ');
  text = text.replace(/fPa/g, 'ඡේ');
  text = text.replace(/fVa/g, 'ඪේ');
  text = text.replace(/f>a/g, 'ඝේ');
  text = text.replace(/fÄ/g, 'ඛේ');
  text = text.replace(/f<a/g, 'ළේ');
  text = text.replace(/f`Na/g, 'ඟේ');
  text = text.replace(/fKa/g, 'ණේ');
  text = text.replace(/fMa/g, 'ඵේ');
  text = text.replace(/fGa/g, 'ඨේ');
  text = text.replace(/fâ/g, 'ඬේ');
  text = text.replace(/fYa/g, 'ශේ');
  text = text.replace(/f\{a/g, 'ඥේ');
  text = text.replace(/f\|a/g, 'ඳේ');
  text = text.replace(/fËa/g, 'ක්‍ෂේ');
  text = text.replace(/fí/g, 'බේ');
  text = text.replace(/fÉ/g, 'චේ');
  text = text.replace(/fâ/g, 'ඩේ');
  text = text.replace(/f\*a/g, 'ෆේ');
  text = text.replace(/f\.a/g, 'ගේ');
  text = text.replace(/fya/g, 'හේ');
  text = text.replace(/fca/g, 'ජේ');
  text = text.replace(/fla/g, 'කේ');
  text = text.replace(/f,a/g, 'ලේ');
  text = text.replace(/fï/g, 'මේ');
  text = text.replace(/fka/g, 'නේ');
  text = text.replace(/fma/g, 'පේ');
  text = text.replace(/foa/g, 'දේ');
  text = text.replace(/f¾/g, 'රේ');
  text = text.replace(/fia/g, 'සේ');
  text = text.replace(/fÜ/g, 'ටේ');
  text = text.replace(/fõ/g, 'වේ');
  text = text.replace(/f\;a/g, 'තේ');
  text = text.replace(/fNa/g, 'භේ');
  text = text.replace(/fha/g, 'යේ');
  text = text.replace(/f\[a/g, 'ඤේ');
  text = text.replace(/fè/g, 'ධේ');
  text = text.replace(/f\:a/g, 'ථේ');
  text = text.replace(/ta/g, 'ඒ');
  text = text.replace(/fÊ/g, 'ජේ');
  text = text.replace(/wE/g, 'ඈ');
  text = text.replace(/fI/g, 'ෂෙ');
  text = text.replace(/fU/g, 'ඹෙ');
  text = text.replace(/ft/g, 'ඓ');
  text = text.replace(/fP/g, 'ඡෙ');
  text = text.replace(/fV/g, 'ඪෙ');
  text = text.replace(/f>/g, 'ඝෙ');
  text = text.replace(/fL/g, 'ඛෙ');
  text = text.replace(/f</g, 'ළෙ');
  text = text.replace(/f`y/g, 'ඟෙ');
  text = text.replace(/fK/g, 'ණෙ');
  text = text.replace(/fM/g, 'ඵෙ');
  text = text.replace(/fG/g, 'ඨෙ');
  text = text.replace(/f~/g, 'ඬෙ');
  text = text.replace(/fY/g, 'ශෙ');
  text = text.replace(/f\{/g, 'ඥෙ');
  text = text.replace(/f\|/g, 'ඳෙ');
  text = text.replace(/fCI/g, 'ක්‍ෂෙ');
  text = text.replace(/fn/g, 'බෙ');
  text = text.replace(/fp/g, 'චෙ');
  text = text.replace(/fv/g, 'ඩෙ');
  text = text.replace(/f\*/g, 'ෆෙ');
  text = text.replace(/f\./g, 'ගෙ');
  text = text.replace(/fy/g, 'හෙ');
  text = text.replace(/fc/g, 'ජෙ');
  text = text.replace(/fl/g, 'කෙ');
  text = text.replace(/f,/g, 'ලෙ');
  text = text.replace(/fu/g, 'මෙ');
  text = text.replace(/fk/g, 'නෙ');
  text = text.replace(/fm/g, 'පෙ');
  text = text.replace(/fo/g, 'දෙ');
  text = text.replace(/fr/g, 'රෙ');
  text = text.replace(/fi/g, 'සෙ');
  text = text.replace(/fg/g, 'ටෙ');
  text = text.replace(/fj/g, 'වෙ');
  text = text.replace(/f\;/g, 'තෙ');
  text = text.replace(/fN/g, 'භෙ');
  text = text.replace(/fh/g, 'යෙ');
  text = text.replace(/f\[/g, 'ඤෙ');
  text = text.replace(/fO/g, 'ධෙ');
  text = text.replace(/f\:/g, 'ථෙ');
  text = text.replace(/IDD/g, 'ෂෲ');
  text = text.replace(/YDD/g, 'ශෲ');
  text = text.replace(/nDD/g, 'බෲ');
  text = text.replace(/vDD/g, 'ඩෲ');
  text = text.replace(/\*DD/g, 'ෆෲ');
  text = text.replace(/\.DD/g, 'ගෲ');
  text = text.replace(/lDD/g, 'කෲ');
  text = text.replace(/mDD/g, 'පෲ');
  text = text.replace(/iDD/g, 'සෲ');
  text = text.replace(/gDD/g, 'ටෲ');
  text = text.replace(/\;DD/g, 'තෲ');
  text = text.replace(/NDD/g, 'භෲ');
  text = text.replace(/ODD/g, 'ධෲ');
  text = text.replace(/Ï/g, 'ඐ');
  text = text.replace(/rE/g, 'රූ');
  text = text.replace(/W!/g, 'ඌ');
  text = text.replace(/T!/g, 'ඖ');
  text = text.replace(/Ï/g, 'ඐ');
  text = text.replace(/Æ/g, 'ලූ');
  text = text.replace(/re/g, 'රු');
  text = text.replace(/\//g, 'රැ=');
  text = text.replace(/ƒ/g, 'ඳැ=');
  text = text.replace(/\//g, 'රැ');
  text = text.replace(/R/g, 'ඍ');
  text = text.replace(/¨/g, 'ලූ');
  text = text.replace(/§/g, 'දී');
  text = text.replace(/ø/g, 'ද්‍ර');
  text = text.replace(/÷/g, 'ඳු');
  text = text.replace(/ÿ/g, 'දු');
  text = text.replace(/ü/g, 'ඤූ=');
  text = text.replace(/û/g, 'ඤු=');
  text = text.replace(/£/g, 'ඳී');
  text = text.replace(/°/g, 'ඣී');
  text = text.replace(/Á/g, 'ඨී');
  text = text.replace(/Â/g, 'ඡී');
  text = text.replace(/Ç/g, 'ඛී');
  text = text.replace(/Í/g, 'රී');
  text = text.replace(/Ð/g, 'ඪී');
  text = text.replace(/Ò/g, 'ථී');
  text = text.replace(/Ô/g, 'ජී');
  text = text.replace(/Ö/g, 'චී');
  text = text.replace(/Ú/g, 'ඵී');
  text = text.replace(/Ý/g, 'ඵී');
  text = text.replace(/à/g, 'ටී');
  text = text.replace(/ã/g, 'ඞී');
  text = text.replace(/é/g, 'ඬී');
  text = text.replace(/ë/g, 'ධී');
  text = text.replace(/î/g, 'බී');
  text = text.replace(/ó/g, 'මී');
  text = text.replace(/ö/g, 'ඹී');
  text = text.replace(/ù/g, 'වී');
  text = text.replace(/Ú/g, 'ඵී');
  text = text.replace(/Œ/g, 'ණී');
  text = text.replace(/B/g, 'ඊ');
  text = text.replace(/b/g, 'ඉ');
  text = text.replace(/¢/g, 'ඳි');
  text = text.replace(/È/g, 'දි');
  text = text.replace(/¯/g, 'ඣි');
  text = text.replace(/À/g, 'ඨි');
  text = text.replace(/Å/g, 'ඛි');
  text = text.replace(/È/g, 'දි');
  text = text.replace(/ß/g, 'රි');
  text = text.replace(/Î/g, 'ඪි');
  text = text.replace(/Ñ/g, 'චි');
  text = text.replace(/Ó/g, 'ථි');
  text = text.replace(/á/g, 'ටි');
  text = text.replace(/ä/g, 'ඩි');
  text = text.replace(/ç/g, 'ඬි');
  text = text.replace(/ê/g, 'ධි');
  text = text.replace(/ì/g, 'බි');
  text = text.replace(/ñ/g, 'මි');
  text = text.replace(/ð/g, 'ජි');
  text = text.replace(/ô/g, 'ඹි');
  text = text.replace(/ú/g, 'වි');
  text = text.replace(/Ý/g, 'ඵි');
  text = text.replace(/ˉ/g, 'ඣි');
  text = text.replace(/‚/g, 'ණි');
  text = text.replace(/þ/g, 'ඡ්');
  text = text.replace(/Ü/g, 'ට්');
  text = text.replace(/Ù/g, 'ඕ');
  text = text.replace(/õ/g, 'ව්');
  text = text.replace(/ò/g, 'ඹ්');
  text = text.replace(/ï/g, 'ම්');
  text = text.replace(/í/g, 'බ්');
  text = text.replace(/è/g, 'ධ්');
  text = text.replace(/å/g, 'ඬ්');
  text = text.replace(/â/g, 'ඩ්'); // Update
  text = text.replace(/Ü/g, 'ට්');
  text = text.replace(/Ù/g, 'ඕ'); // Update
  text = text.replace(/´/g, ' ඕ');
  text = text.replace(/¾/g, 'ර්');
  text = text.replace(/Ä/g, 'ඛ්');
  text = text.replace(/É/g, 'ච්');
  text = text.replace(/Ê/g, 'ජ්');
  text = text.replace(/wd/g, 'ආ');
  text = text.replace(/we/g, 'ඇ');
  text = text.replace(/P/g, 'ඡ=');
  text = text.replace(/X/g, 'ඞ');
  text = text.replace(/r/g, 'ර');
  text = text.replace(/Ì/g, 'ඏ');
  text = text.replace(/“/g, ' ර්‍ණ');
  text = text.replace(/I/g, 'ෂ');
  text = text.replace(/U/g, 'ඹ');
  text = text.replace(/c/g, 'ජ');
  text = text.replace(/V/g, 'ඪ');
  text = text.replace(/>/g, 'ඝ');
  text = text.replace(/CO/g, 'ඣ');
  text = text.replace(/L/g, 'ඛ');
  text = text.replace(/</g, 'ළ');
  text = text.replace(/\`y/g, 'ඟ');
  text = text.replace(/K/g, 'ණ');
  text = text.replace(/M/g, 'ඵ');
  text = text.replace(/G/g, 'ඨ');
  text = text.replace(/¿/g, 'ළු');
  text = text.replace(/~/g, 'ඬ');
  text = text.replace(/Y/g, 'ශ');
  text = text.replace(/{/g, 'ඥ');
  text = text.replace(/\|/g, 'ඳ');
  text = text.replace(/Ë/g, 'ක්‍ෂ');
  text = text.replace(/CI/g, 'ක්‍ෂ');
  text = text.replace(/®/g, 'ඣ');
  text = text.replace(/Õ/g, 'ඟ');
  text = text.replace(/×/g, 'ඥ');
  text = text.replace(/Ø/g, 'ඤ');
  text = text.replace(/Ì/g, 'ඏ');
  text = text.replace(/t/g, 'එ');
  text = text.replace(/w/g, 'අ');
  text = text.replace(/n/g, 'බ');
  text = text.replace(/p/g, 'ච');
  text = text.replace(/v/g, 'ඩ');
  text = text.replace(/M/g, 'ඵ');
  text = text.replace(/\*/g, 'ෆ');
  text = text.replace(/\./g, 'ග');
  text = text.replace(/y/g, 'හ');
  text = text.replace(/c/g, 'ජ');
  text = text.replace(/l/g, 'ක');
  text = text.replace(/,/g, 'ල');
  text = text.replace(/u/g, 'ම');
  text = text.replace(/k/g, 'න');
  text = text.replace(/T/g, 'ඔ');
  text = text.replace(/m/g, 'ප');
  text = text.replace(/o/g, 'ද');
  text = text.replace(/r/g, 'ර');
  text = text.replace(/i/g, 'ස');
  text = text.replace(/g/g, 'ට');
  text = text.replace(/W/g, 'උ');
  text = text.replace(/j/g, 'ව');
  text = text.replace(/;/g, 'ත');
  text = text.replace(/N/g, 'භ');
  text = text.replace(/h/g, 'ය');
  text = text.replace(/\[/g, 'ඤ');
  text = text.replace(/\{/g, 'ඥ');
  text = text.replace(/\|/g, 'ඳ');
  text = text.replace(/~/g, 'ඬ');
  text = text.replace(/O/g, 'ධ');
  text = text.replace(/\:/g, 'ථ');
  text = text.replace(/\…/g, 'ත්‍ව');
  text = text.replace(/‡/g, 'න්‍ද');
  text = text.replace(/†/g, 'ත්‍ථ');
  text = text.replace(/F/g, 'ත්‍');
  text = text.replace(/J/g, 'න්‍');
  text = text.replace(/C/g, 'ක්‍');
  text = text.replace(/Þ/g, 'දා');
  text = text.replace(/±/g, 'දැ');
  text = text.replace(/ˆ/g, 'න්‍දා');
  text = text.replace(/H/g, '්‍ය');
  text = text.replace(/%/g, '‍්‍ර');
  text = text.replace(/f/g, 'ෙ');
  text = text.replace(/e/g, 'ැ');
  text = text.replace(/E/g, 'ෑ');
  text = text.replace(/q/g, 'ු');
  text = text.replace(/s/g, 'ි');
  text = text.replace(/Q/g, 'ූ');
  text = text.replace(/=/g, 'ු');
  text = text.replace(/\+/g, 'ූ');
  text = text.replace(/S/g, 'ී');
  text = text.replace(/D/g, 'ෘ');
  text = text.replace(/!/g, 'ෟ');
  text = text.replace(/d/g, 'ා');
  text = text.replace(/A/g, '්');
  text = text.replace(/a/g, '්');
  text = text.replace(/x/g, 'ං');
  text = text.replace(/½/g, 'ඃ');
  text = text.replace(/ ’/g, 'ී');
  text = text.replace(/ ‘/g, 'ි');
  text = text.replace(/#/g, 'ඃ');
  text = text.replace(/œ/g, ' ර්‍්‍ය');
  text = text.replace(/˜/g, '”');
  text = text.replace(/—/g, '”');
  text = text.replace(/™/g, '{');
  text = text.replace(/∙/g, '×');
  text = text.replace(/š/g, '}');
  text = text.replace(/•/g, 'x');
  text = text.replace(/²/g, '•');
  text = text.replace(/­/g, '÷');
  text = text.replace(/¬/g, '+');
  text = text.replace(/«/g, '×');
  text = text.replace(/}/g, '=');
  text = text.replace(/−/g, '÷');
  text = text.replace(/"/g, '□');
  text = text.replace(/æ/g, '!');
  text = text.replace(/\$/g, '/');
  text = text.replace(/\&/g, ')');
  text = text.replace(/\(/g, ':');
  text = text.replace(/\)/g, '*');
  text = text.replace(/-/g, '-');
  text = text.replace(/@/g, '?');
  text = text.replace(/Z/g, '’');
  text = text.replace(/z/g, '‘');
  text = text.replace(/\]/g, '%');
  text = text.replace(/\^/g, '(');
  text = text.replace(/&/g, ')');
  text = text.replace(/Z/g, '’');
  text = text.replace(/¡/g, '•');
  text = text.replace(/¤/g, '–');
  text = text.replace(/\¦/g, ';');
  text = text.replace(/\³/g, '⋆');
  text = text.replace(/µ/g, 'i');
  text = text.replace(/¶/g, 'v');
  text = text.replace(/•/g, 'x');
  text = text.replace(/¸/g, 'I');
  text = text.replace(/¹/g, 'V');
  text = text.replace(/º/g, 'X');
  text = text.replace(/º/g, 'X');
  text = text.replace(/¹/g, 'V');
  text = text.replace(/Ã/g, '.');
  text = text.replace(/»/g, ',');

  return text;
};

const dlToUnicode = (text) => {
  if (!text || text === null) {
    return null;
  }
  text = text.replace(/\'/g, '♥');
  text = text.replace(/\^/g, 'Ѫ');
  text = text.replace(/\&/g, 'Ѧ');
  text = text.replace(/\"/g, 'ʘ');
  text = text.replace(/@/g, 'Ϣ');
  text = text.replace(/\(/g, 'ƨ');
  text = text.replace(/\]/g, 'Ɣ');
  text = text.replace(/\$/g, 'ɤ');
  text = text.replace(/ff;%/g, 'ත්‍රෛ');
  text = text.replace(/ffY/g, 'ශෛ');
  text = text.replace(/ffp/g, 'චෛ');
  text = text.replace(/ffc/g, 'ජෛ');
  text = text.replace(/ffl/g, 'කෛ');
  text = text.replace(/ffu/g, 'මෛ');
  text = text.replace(/ffm/g, 'පෛ');
  text = text.replace(/ffo/g, 'දෛ');
  text = text.replace(/ff;/g, 'තෛ');
  text = text.replace(/ffk/g, 'නෛ');
  text = text.replace(/ffO/g, 'ධෛ');
  text = text.replace(/ffj/g, 'වෛ');
  text = text.replace(/fm%!/g, 'ප්‍රෞ');
  text = text.replace(/fIHda/g, 'ෂ්‍යෝ');
  text = text.replace(/fPHda/g, 'ඡ්‍යෝ');
  text = text.replace(/fVHda/g, 'ඪ්‍යෝ');
  text = text.replace(/f>Hda/g, 'ඝ්‍යෝ');
  text = text.replace(/fLHda/g, 'ඛ්‍යෝ');
  text = text.replace(/f<Hda/g, 'ළ්‍යෝ');
  text = text.replace(/fMHda/g, 'ඵ්‍යෝ');
  text = text.replace(/fGHda/g, 'ඨ්‍යෝ');
  text = text.replace(/fYHda/g, 'ශ්‍යෝ');
  text = text.replace(/fÌHda/g, 'ක්‍ෂ්‍යෝ');
  text = text.replace(/fnHda/g, 'බ්‍යෝ');
  text = text.replace(/fpHda/g, 'ච්‍යෝ');
  text = text.replace(/fâHda/g, 'ඩ්‍යෝ');
  text = text.replace(/f\*Hda/g, 'ෆ්‍යෝ');
  text = text.replace(/f\.Hda/g, 'ග්‍යෝ');
  text = text.replace(/fcHda/g, 'ජ්‍යෝ');
  text = text.replace(/flHda/g, 'ක්‍යෝ');
  text = text.replace(/f,Hda/g, 'ල්‍යෝ');
  text = text.replace(/fuHda/g, 'ම්‍යෝ');
  text = text.replace(/fkHda/g, 'න්‍යෝ');
  text = text.replace(/fmHda/g, 'ප්‍යෝ');
  text = text.replace(/foHda/g, 'ද්‍යෝ');
  text = text.replace(/fiHda/g, 'ස්‍යෝ');
  text = text.replace(/fgHda/g, 'ට්‍යෝ');
  text = text.replace(/fjHda/g, 'ව්‍යෝ');
  text = text.replace(/f;Hda/g, 'ත්‍යෝ');
  text = text.replace(/fNHda/g, 'භ්‍යෝ');
  text = text.replace(/fOHda/g, 'ධ්‍යෝ');
  text = text.replace(/f:Hda/g, 'ථ්‍යෝ');
  text = text.replace(/fIHd/g, 'ෂ්‍යො');
  text = text.replace(/fYHd/g, 'ශ්‍යො');
  text = text.replace(/fLHd/g, 'ඛ්‍යො');
  text = text.replace(/fÌHd/g, 'ක්‍ෂ්‍යො');
  text = text.replace(/fnHd/g, 'බ්‍යො');
  text = text.replace(/fjHd/g, 'ව්‍යො');
  text = text.replace(/fvHd/g, 'ඩ්‍යො');
  text = text.replace(/f\*Hd/g, 'ෆ්‍යො');
  text = text.replace(/f\.Hd/g, 'ග්‍යො');
  text = text.replace(/fcHd/g, 'ජ්‍යො');
  text = text.replace(/flHd/g, 'ක්‍යො');
  text = text.replace(/fuHd/g, 'ම්‍යො');
  text = text.replace(/fmHd/g, 'ප්‍යො');
  text = text.replace(/foHd/g, 'ද්‍යො');
  text = text.replace(/fiHd/g, 'ස්‍යො');
  text = text.replace(/fgHd/g, 'ට්‍යො');
  text = text.replace(/fjHd/g, 'ව්‍යො');
  text = text.replace(/fkHd/g, 'න්‍යො');
  text = text.replace(/f;Hd/g, 'ත්‍යො');
  text = text.replace(/fNHd/g, 'භ්‍යො');
  text = text.replace(/fOHd/g, 'ධ්‍යො');
  text = text.replace(/f:Hd/g, 'ථ්‍යො');
  text = text.replace(/fIH/g, 'ෂ්‍යෙ');
  text = text.replace(/fPH/g, 'ඡ්‍යෙ');
  text = text.replace(/f<H/g, 'ළ්‍යෙ');
  text = text.replace(/fKH/g, 'ණ්‍යෙ');
  text = text.replace(/fpH/g, 'ච්‍යෙ');
  text = text.replace(/f,H/g, 'ල්‍යෙ');
  text = text.replace(/fkH/g, 'න්‍යෙ');
  text = text.replace(/fYH/g, 'ශ්‍යෙ');
  text = text.replace(/fLH/g, 'ඛ්‍යෙ');
  text = text.replace(/fÌH/g, 'ක්‍ෂ්‍ය');
  text = text.replace(/fnH/g, 'බ්‍යෙ');
  text = text.replace(/fvH/g, 'ඩ්‍යෙ');
  text = text.replace(/f\*H/g, 'ෆ්‍යෙ');
  text = text.replace(/f\.H/g, 'ග්‍යෙ');
  text = text.replace(/fcH/g, 'ජ්‍යෙ');
  text = text.replace(/flH/g, 'ක්‍යෙ');
  text = text.replace(/fuH/g, 'ම්‍යෙ');
  text = text.replace(/fmH/g, 'ප්‍යෙ');
  text = text.replace(/foH/g, 'ද්‍යෙ');
  text = text.replace(/fiH/g, 'ස්‍යෙ');
  text = text.replace(/fgH/g, 'ට්‍යෙ');
  text = text.replace(/fjH/g, 'ව්‍යෙ');
  text = text.replace(/f;H/g, 'ත්‍යෙ');
  text = text.replace(/fNH/g, 'භ්‍යෙ');
  text = text.replace(/fOH/g, 'ධ්‍යෙ');
  text = text.replace(/f:H/g, 'ථ්‍යෙ');
  text = text.replace(/fI%da/g, 'ෂ්‍රෝ');
  text = text.replace(/f>%da/g, 'ඝ්‍රෝ');
  text = text.replace(/fY%da/g, 'ශ්‍රෝ');
  text = text.replace(/fÌ%da/g, 'ක්‍ෂ්‍රෝ');
  text = text.replace(/fn%da/g, 'බ්‍රෝ');
  text = text.replace(/fv%da/g, 'ඩ්‍රෝ');
  text = text.replace(/f\*%da/g, 'ෆ්‍රෝ');
  text = text.replace(/f\.%da/g, 'ග්‍රෝ');
  text = text.replace(/fl%da/g, 'ක්‍රෝ');
  text = text.replace(/fm%da/g, 'ප්‍රෝ');
  text = text.replace(/føda/g, 'ද්‍රෝ');
  text = text.replace(/fi%da/g, 'ස්‍රෝ');
  text = text.replace(/fg%da/g, 'ට්‍රෝ');
  text = text.replace(/f;%da/g, 'ත්‍රෝ');
  text = text.replace(/fY%d/g, 'ශ්‍රො');
  text = text.replace(/fv%d/g, 'ඩ්‍රො');
  text = text.replace(/f\*%d/g, 'ෆ්‍රො');
  text = text.replace(/f\.%d/g, 'ග්‍රො');
  text = text.replace(/fl%d/g, 'ක්‍රො');
  text = text.replace(/fm%d/g, 'ප්‍රො');
  text = text.replace(/fød/g, 'ද්‍රො');
  text = text.replace(/fi%d/g, 'ස්‍රො');
  text = text.replace(/fg%d/g, 'ට්‍රො');
  text = text.replace(/f;%d/g, 'ත්‍රො');
  text = text.replace(/%a/g, 'a%');
  text = text.replace(/fYa%/g, 'ශ්‍රේ');
  text = text.replace(/fí%/g, 'බ්‍රේ');
  text = text.replace(/fâ%/g, 'ඩ්‍රේ');
  text = text.replace(/f\*a%/g, 'ෆ්‍රේ');
  text = text.replace(/f\.a%/g, 'ග්‍රේ');
  text = text.replace(/fla%/g, 'ක්‍රේ');
  text = text.replace(/fma%/g, 'ප්‍රේ');
  text = text.replace(/føa/g, 'ද්‍රේ');
  text = text.replace(/fia%/g, 'ස්‍රේ');
  text = text.replace(/f;a%/g, 'ත්‍රේ');
  text = text.replace(/fè%/g, 'ධ්‍රේ');
  text = text.replace(/fI%/g, 'ෂ්‍රෙ');
  text = text.replace(/fY%/g, 'ශ්‍රෙ');
  text = text.replace(/fn%/g, 'බ්‍රෙ');
  text = text.replace(/f\*%/g, 'ෆ්‍රෙ');
  text = text.replace(/f\.%/g, 'ග්‍රෙ');
  text = text.replace(/fl%/g, 'ක්‍රෙ');
  text = text.replace(/fm%/g, 'ප්‍රෙ');
  text = text.replace(/fø/g, 'ද්‍රෙ');
  text = text.replace(/fi%/g, 'ස්‍රෙ');
  text = text.replace(/f;%/g, 'ත්‍රෙ');
  text = text.replace(/fN%/g, 'භ්‍රෙ');
  text = text.replace(/fO%/g, 'ධ්‍රෙ');
  text = text.replace(/H/g, '්‍ය');
  text = text.replace(/%/g, '්‍ර');
  text = text.replace(/fI!/g, 'ෂෞ');
  text = text.replace(/fP!/g, 'ඡෞ');
  text = text.replace(/fY!/g, 'ශෞ');
  text = text.replace(/fn!/g, 'බෞ');
  text = text.replace(/fp!/g, 'චෞ');
  text = text.replace(/fv!/g, 'ඩෞ');
  text = text.replace(/f\*!/g, 'ෆෞ');
  text = text.replace(/f\.!/g, 'ගෞ');
  text = text.replace(/fc!/g, 'ජෞ');
  text = text.replace(/fl!/g, 'කෞ');
  text = text.replace(/f,!/g, 'ලෞ');
  text = text.replace(/fu!/g, 'මෞ');
  text = text.replace(/fk!/g, 'නෞ');
  text = text.replace(/fm!/g, 'පෞ');
  text = text.replace(/fo!/g, 'දෞ');
  text = text.replace(/fr!/g, 'රෞ');
  text = text.replace(/fi!/g, 'සෞ');
  text = text.replace(/fg!/g, 'ටෞ');
  text = text.replace(/f;!/g, 'තෞ');
  text = text.replace(/fN!/g, 'භෞ');
  text = text.replace(/f\[!/g, 'ඤෞ');
  text = text.replace(/fIda/g, 'ෂෝ');
  text = text.replace(/fUda/g, 'ඹෝ');
  text = text.replace(/fPda/g, 'ඡෝ');
  text = text.replace(/fVda/g, 'ඪෝ');
  text = text.replace(/f>da/g, 'ඝෝ');
  text = text.replace(/fLda/g, 'ඛෝ');
  text = text.replace(/f<da/g, 'ළෝ');
  text = text.replace(/fÛda/g, 'ඟෝ');
  text = text.replace(/fKda/g, 'ණෝ');
  text = text.replace(/fMda/g, 'ඵෝ');
  text = text.replace(/fGda/g, 'ඨෝ');
  text = text.replace(/fËda/g, 'ඬෝ');
  text = text.replace(/fYda/g, 'ශෝ');
  text = text.replace(/f\{da/g, 'ඥෝ');
  text = text.replace(/f\|da/g, 'ඳෝ');
  text = text.replace(/fÌda/g, 'ක්‍ෂෝ');
  text = text.replace(/fnda/g, 'බෝ');
  text = text.replace(/fpda/g, 'චෝ');
  text = text.replace(/fvda/g, 'ඩෝ');
  text = text.replace(/f\*da/g, 'ෆෝ');
  text = text.replace(/f\.da/g, 'ගෝ');
  text = text.replace(/fyda/g, 'හෝ');
  text = text.replace(/fcda/g, 'ජෝ');
  text = text.replace(/flda/g, 'කෝ');
  text = text.replace(/f,da/g, 'ලෝ');
  text = text.replace(/fuda/g, 'මෝ');
  text = text.replace(/fkda/g, 'නෝ');
  text = text.replace(/fmda/g, 'පෝ');
  text = text.replace(/foda/g, 'දෝ');
  text = text.replace(/frda/g, 'රෝ');
  text = text.replace(/fida/g, 'සෝ');
  text = text.replace(/fgda/g, 'ටෝ');
  text = text.replace(/fjda/g, 'වෝ');
  text = text.replace(/f;da/g, 'තෝ');
  text = text.replace(/fNda/g, 'භෝ');
  text = text.replace(/fhda/g, 'යෝ');
  text = text.replace(/f\[da/g, 'ඤෝ');
  text = text.replace(/fOda/g, 'ධෝ');
  text = text.replace(/f:da/g, 'ථෝ');
  text = text.replace(/fId/g, 'ෂො');
  text = text.replace(/fUd/g, 'ඹො');
  text = text.replace(/fPd/g, 'ඡො');
  text = text.replace(/fVd/g, 'ඪො');
  text = text.replace(/f>d/g, 'ඝො');
  text = text.replace(/fLd/g, 'ඛො');
  text = text.replace(/f<d/g, 'ළො');
  text = text.replace(/fÕd/g, 'ඟො');
  text = text.replace(/fKd/g, 'ණො');
  text = text.replace(/fMd/g, 'ඵො');
  text = text.replace(/fGd/g, 'ඨො');
  text = text.replace(/fËd/g, 'ඬො');
  text = text.replace(/fYd/g, 'ශො');
  text = text.replace(/f\{d/g, 'ඥො');
  text = text.replace(/f\|d/g, 'ඳො');
  text = text.replace(/fÌd/g, 'ක්‍ෂො');
  text = text.replace(/fnd/g, 'බො');
  text = text.replace(/fpd/g, 'චො');
  text = text.replace(/fvd/g, 'ඩො');
  text = text.replace(/f\*d/g, 'ෆො');
  text = text.replace(/f\.d/g, 'ගො');
  text = text.replace(/fyd/g, 'හො');
  text = text.replace(/fcd/g, 'ජො');
  text = text.replace(/fld/g, 'කො');
  text = text.replace(/f,d/g, 'ලො');
  text = text.replace(/fud/g, 'මො');
  text = text.replace(/fkd/g, 'නො');
  text = text.replace(/fmd/g, 'පො');
  text = text.replace(/fod/g, 'දො');
  text = text.replace(/frd/g, 'රො');
  text = text.replace(/fid/g, 'සො');
  text = text.replace(/fgd/g, 'ටො');
  text = text.replace(/fjd/g, 'වො');
  text = text.replace(/f;d/g, 'තො');
  text = text.replace(/fNd/g, 'භො');
  text = text.replace(/fhd/g, 'යො');
  text = text.replace(/f\[d/g, 'ඤො');
  text = text.replace(/fOd/g, 'ධො');
  text = text.replace(/f:d/g, 'ථො');
  text = text.replace(/fIa/g, 'ෂේ');
  text = text.replace(/fò/g, 'ඹේ');
  text = text.replace(/fþ/g, 'ඡේ');
  text = text.replace(/f\\a/g, 'ඪේ');
  text = text.replace(/f>a/g, 'ඝේ');
  text = text.replace(/fÄ/g, 'ඛේ');
  text = text.replace(/f<a/g, 'ළේ');
  text = text.replace(/fÛa/g, 'ඟේ');
  text = text.replace(/fKa/g, 'ණේ');
  text = text.replace(/fMa/g, 'ඵේ');
  text = text.replace(/fGa/g, 'ඨේ');
  text = text.replace(/få/g, 'ඬේ');
  text = text.replace(/fYa/g, 'ශේ');
  text = text.replace(/f\{a/g, 'ඥේ');
  text = text.replace(/f\|a/g, 'ඳේ');
  text = text.replace(/fÌa/g, 'ක්‍ෂේ');
  text = text.replace(/fí/g, 'බේ');
  text = text.replace(/fÉ/g, 'චේ');
  text = text.replace(/fâ/g, 'ඩේ');
  text = text.replace(/f\*a/g, 'ෆේ');
  text = text.replace(/f\.a/g, 'ගේ');
  text = text.replace(/fya/g, 'හේ');
  text = text.replace(/fma/g, 'පේ');
  text = text.replace(/fla/g, 'කේ');
  text = text.replace(/f,a/g, 'ලේ');
  text = text.replace(/fï/g, 'මේ');
  text = text.replace(/fka/g, 'නේ');
  text = text.replace(/f\–/g, 'ජේ');
  text = text.replace(/foa/g, 'දේ');
  text = text.replace(/f¾/g, 'රේ');
  text = text.replace(/fia/g, 'සේ');
  text = text.replace(/fÜ/g, 'ටේ');
  text = text.replace(/fõ/g, 'වේ');
  text = text.replace(/f;a/g, 'තේ');
  text = text.replace(/fNa/g, 'භේ');
  text = text.replace(/fha/g, 'යේ');
  text = text.replace(/f\[a/g, 'ඤේ');
  text = text.replace(/fè/g, 'ධේ');
  text = text.replace(/f:a/g, 'ථේ');
  text = text.replace(/fI/g, 'ෂෙ');
  text = text.replace(/fU/g, 'ඹෙ');
  text = text.replace(/ft/g, 'ඓ');
  text = text.replace(/fP/g, 'ඡෙ');
  text = text.replace(/fV/g, 'ඪෙ');
  text = text.replace(/f>/g, 'ඝෙ');
  text = text.replace(/fn/g, 'ඛෙ');
  text = text.replace(/f</g, 'ළෙ');
  text = text.replace(/fÛ/g, 'ඟෙ');
  text = text.replace(/fK/g, 'ණෙ');
  text = text.replace(/fM/g, 'ඵෙ');
  text = text.replace(/fG/g, 'ඨෙ');
  text = text.replace(/fË/g, 'ඬෙ');
  text = text.replace(/fY/g, 'ශෙ');
  text = text.replace(/f\{/g, 'ඥෙ');
  text = text.replace(/fË/g, 'ඳෙ');
  text = text.replace(/fÌ/g, 'ක්‍ෂෙ');
  text = text.replace(/fn/g, 'බෙ');
  text = text.replace(/fp/g, 'චෙ');
  text = text.replace(/fv/g, 'ඩෙ');
  text = text.replace(/f\*/g, 'ෆෙ');
  text = text.replace(/f\./g, 'ගෙ');
  text = text.replace(/fy/g, 'හෙ');
  text = text.replace(/fc/g, 'ජෙ');
  text = text.replace(/fl/g, 'කෙ');
  text = text.replace(/f,/g, 'ලෙ');
  text = text.replace(/fu/g, 'මෙ');
  text = text.replace(/fk/g, 'නෙ');
  text = text.replace(/fm/g, 'පෙ');
  text = text.replace(/fo/g, 'දෙ');
  text = text.replace(/fr/g, 'රෙ');
  text = text.replace(/fi/g, 'සෙ');
  text = text.replace(/fg/g, 'ටෙ');
  text = text.replace(/fj/g, 'වෙ');
  text = text.replace(/f;/g, 'තෙ');
  text = text.replace(/fN/g, 'භෙ');
  text = text.replace(/fh/g, 'යෙ');
  text = text.replace(/f\[/g, 'ඤෙ');
  text = text.replace(/fO/g, 'ධෙ');
  text = text.replace(/f:/g, 'ථෙ');
  text = text.replace(/;=/g, 'තු');
  text = text.replace(/\.=/g, 'ගු');
  text = text.replace(/l=/g, 'කු');
  text = text.replace(/;\+/g, 'තූ');
  text = text.replace(/N\+/g, 'භූ');
  text = text.replace(/N\=/g, 'භු');
  text = text.replace(/\.\+/g, 'ගූ');
  text = text.replace(/l\+/g, 'කූ');
  text = text.replace(/re/g, 'රු');
  text = text.replace(/rE/g, 'රූ');
  text = text.replace(/wd/g, 'ආ');
  text = text.replace(/we/g, 'ඇ');
  text = text.replace(/wE/g, 'ඈ');
  text = text.replace(/W!/g, 'ඌ');
  text = text.replace(/T!/g, 'ඖ');
  text = text.replace(/ta/g, 'ඒ');
  text = text.replace(/\´/g, 'ඕ');
  text = text.replace(/¢/g, 'ඳි');
  text = text.replace(/£/g, 'ඳී');
  text = text.replace(/¥/g, 'දූ');
  text = text.replace(/§/g, 'දී');
  text = text.replace(/\¨/g, 'ලූ');
  text = text.replace(/©/g, 'ර්‍ය');
  text = text.replace(/ª/g, 'ඳූ');
  text = text.replace(/¾/g, 'ර්');
  text = text.replace(/À/g, 'ඨි');
  text = text.replace(/Á/g, 'ඨී');
  text = text.replace(/Â/g, 'ඡී');
  text = text.replace(/Ä/g, 'ඛ්');
  text = text.replace(/Å/g, 'ඛි');
  text = text.replace(/Æ/g, 'ලු');
  text = text.replace(/Ç/g, 'ඛී');
  text = text.replace(/È/g, 'දි');
  text = text.replace(/É/g, 'ච්');
  text = text.replace(/Ê/g, 'ජ්');
  text = text.replace(/Í/g, 'රී');
  text = text.replace(/Î/g, 'ඪී');
  text = text.replace(/Ð/g, 'ඪී');
  text = text.replace(/Ñ/g, 'චි');
  text = text.replace(/Ò/g, 'ථී');
  text = text.replace(/Ó/g, 'ථී');
  text = text.replace(/Ô/g, 'ජී');
  text = text.replace(/Ö/g, 'චී');
  text = text.replace(/Ù/g, 'ඞ්');
  text = text.replace(/Ú/g, 'ඵී');
  text = text.replace(/Ü/g, 'ට්');
  text = text.replace(/Ý/g, 'ඵි');
  text = text.replace(/ß/g, 'රි');
  text = text.replace(/à/g, 'ටී');
  text = text.replace(/á/g, 'ටි');
  text = text.replace(/â/g, 'ඩ්');
  text = text.replace(/ã/g, 'ඩී');
  text = text.replace(/ä/g, 'ඩි');
  text = text.replace(/å/g, 'ඬ්');
  text = text.replace(/ç/g, 'ඬි');
  text = text.replace(/è/g, 'ධ්');
  text = text.replace(/é/g, 'ඬී');
  text = text.replace(/ê/g, 'ධි');
  text = text.replace(/ë/g, 'ධී');
  text = text.replace(/ì/g, 'බි');
  text = text.replace(/í/g, 'බ්');
  text = text.replace(/î/g, 'බී');
  text = text.replace(/ï/g, 'ම්');
  text = text.replace(/ð/g, 'ජි');
  text = text.replace(/ñ/g, 'මි');
  text = text.replace(/ò/g, 'ඹ්');
  text = text.replace(/ó/g, 'මී');
  text = text.replace(/ô/g, 'ඹි');
  text = text.replace(/õ/g, 'ව්');
  text = text.replace(/ö/g, 'ඹී');
  text = text.replace(/÷/g, 'ඳු');
  text = text.replace(/ø/g, 'ද්‍ර');
  text = text.replace(/ù/g, 'වී');
  text = text.replace(/ú/g, 'වි');
  text = text.replace(/û/g, 'ඞ්');
  text = text.replace(/ü/g, 'ඞී');
  text = text.replace(/ý/g, 'ඡි');
  text = text.replace(/þ/g, 'ඡ්');
  text = text.replace(/ÿ/g, 'දු');
  text = text.replace(/±/g, 'දැ');
  text = text.replace(/\–/g, 'ජ්');
  text = text.replace(/\“/g, 'ර්‍ණ');
  text = text.replace(/\"/g, 'ණී');
  text = text.replace(/„/g, 'ජී');
  text = text.replace(/‰/g, 'ඡි');
  text = text.replace(//g, 'ඩි');
  text = text.replace(/™/g, 'ඤු');
  text = text.replace(/\./g, 'ග');
  text = text.replace(/¿/g, 'ළු');
  text = text.replace(/I/g, 'ෂ');
  text = text.replace(/x/g, 'ං');
  text = text.replace(/\#/g, 'ඃ');
  text = text.replace(/U/g, 'ඹ');
  text = text.replace(/P/g, 'ඡ');
  text = text.replace(/V/g, 'ඪ');
  text = text.replace(/>/g, 'ඝ');
  text = text.replace(/B/g, 'ඊ');
  text = text.replace(/CO/g, 'ඣ');
  text = text.replace(/L/g, 'ඛ');
  text = text.replace(/</g, 'ළ');
  text = text.replace(/Û/g, 'ඟ');
  text = text.replace(/Ë/g, 'ඬ');
  text = text.replace(/K/g, 'ණ');
  text = text.replace(/M/g, 'ඵ');
  text = text.replace(/G/g, 'ඨ');
  text = text.replace(/\#/g, 'ඃ');
  text = text.replace(/\&/g, ')');
  text = text.replace(/\(/g, ':');
  text = text.replace(/\)/g, '-');
  text = text.replace(/\*/g, 'ෆ');
  text = text.replace(/\,/g, 'ල');
  text = text.replace(/\-/g, '-');
  text = text.replace(/\//g, 'රැ');
  text = text.replace(/:/g, 'ථ');
  text = text.replace(/;/g, 'ත');
  text = text.replace(/</g, 'ළ');
  text = text.replace(/>/g, 'ඝ');
  text = text.replace(/\?/g, 'රෑ');
  text = text.replace(/B/g, 'ඊ');
  text = text.replace(/C/g, 'ක‍');
  text = text.replace(/F/g, 'ත‍');
  text = text.replace(/G/g, 'ඨ');
  text = text.replace(/H/g, '්‍ය');
  text = text.replace(/I/g, 'ෂ');
  text = text.replace(/J/g, 'න‍');
  text = text.replace(/K/g, 'ණ');
  text = text.replace(/L/g, 'ඛ');
  text = text.replace(/M/g, 'ඵ');
  text = text.replace(/N/g, 'භ');
  text = text.replace(/O/g, 'ධ');
  text = text.replace(/P/g, 'ඡ');
  text = text.replace(/R/g, 'ඍ');
  text = text.replace(/T/g, 'ඔ');
  text = text.replace(/U/g, 'ඹ');
  text = text.replace(/V/g, 'ඪ');
  text = text.replace(/W/g, 'උ');
  text = text.replace(/Y/g, 'ශ');
  text = text.replace(/\[/g, 'ඤ');
  text = text.replace(/b/g, 'ඉ');
  text = text.replace(/c/g, 'ජ');
  text = text.replace(/g/g, 'ට');
  text = text.replace(/h/g, 'ය');
  text = text.replace(/i/g, 'ස');
  text = text.replace(/j/g, 'ව');
  text = text.replace(/k/g, 'න');
  text = text.replace(/l/g, 'ක');
  text = text.replace(/m/g, 'ප');
  text = text.replace(/n/g, 'බ');
  text = text.replace(/o/g, 'ද');
  text = text.replace(/p/g, 'ච');
  text = text.replace(/r/g, 'ර');
  text = text.replace(/t/g, 'එ');
  text = text.replace(/u/g, 'ම');
  text = text.replace(/v/g, 'ඩ');
  text = text.replace(/w/g, 'අ');
  text = text.replace(/y/g, 'හ');
  text = text.replace(/Õ/g, 'ඟ');
  text = text.replace(/\{/g, 'ඥ');
  text = text.replace(/\|/g, 'ඳ');
  text = text.replace(/Ì/g, 'ක්‍ෂ');
  text = text.replace(/µ/g, 'ද්‍ය');
  text = text.replace(/e/g, 'ැ');
  text = text.replace(/E/g, 'ෑ');
  text = text.replace(/f/g, 'ෙ');
  text = text.replace(/q/g, 'ු');
  text = text.replace(/s/g, 'ි');
  text = text.replace(/Q/g, 'ූ');
  text = text.replace(/S/g, 'ී');
  text = text.replace(/D/g, 'ෘ');
  text = text.replace(/DD/g, 'ෲ');
  text = text.replace(/\!/g, 'ෟ');
  text = text.replace(/d/g, 'ා');
  text = text.replace(/a/g, '්');
  text = text.replace(/♥/g, '.');
  text = text.replace(/Ѫ/g, '(');
  text = text.replace(/Ѧ/g, ')');
  text = text.replace(/ʘ/g, ',');
  text = text.replace(/Ϣ/g, '?');
  text = text.replace(/ƨ/g, ':');
  text = text.replace(/Ɣ/g, '%');
  text = text.replace(/ɤ/g, '/');
  return text;
};

const unicodeToDL = (text) => {
  if (!text || text === null) {
    return null;
  }
  text = text.replace(/,/g, '￦');
  text = text.replace(/\./g, '�');
  text = text.replace(/\(/g, '￫');
  text = text.replace(/\)/g, '￩');
  text = text.replace(/%/g, 'ￕ');
  text = text.replace(/\//g, '$');
  text = text.replace(/–/g, 'ￔ');
  text = text.replace(/\?/g, 'ￓ');
  text = text.replace(/!/g, 'ￒ');
  text = text.replace(/\=/g, 'ￏ');
  text = text.replace(/\'/g, 'ￎ');
  text = text.replace(/\+/g, 'ￍ');
  text = text.replace(/\:/g, 'ￌ');
  text = text.replace(/\÷/g, 'ￋ');
  text = text.replace(/\;/g, 'ﾶ');
  text = text.replace(/ත්‍රෛ/g, 'ff;%');
  text = text.replace(/ශෛ/g, 'ffY');
  text = text.replace(/චෛ/g, 'ffp');
  text = text.replace(/ජෛ/g, 'ffc');
  text = text.replace(/කෛ/g, 'ffl');
  text = text.replace(/මෛ/g, 'ffu');
  text = text.replace(/පෛ/g, 'ffm');
  text = text.replace(/දෛ/g, 'ffo');
  text = text.replace(/තෛ/g, 'ff;');
  text = text.replace(/නෛ/g, 'ffk');
  text = text.replace(/ධෛ/g, 'ffO');
  text = text.replace(/වෛ/g, 'ffj');
  text = text.replace(/ප්‍රෞ/g, 'fm%!');
  text = text.replace(/ෂ්‍යෝ/g, 'fIHda');
  text = text.replace(/ඡ්‍යෝ/g, 'fPHda');
  text = text.replace(/ඪ්‍යෝ/g, 'fVHda');
  text = text.replace(/ඝ්‍යෝ/g, 'f>Hda');
  text = text.replace(/ඛ්‍යෝ/g, 'fLHda');
  text = text.replace(/ළ්‍යෝ/g, 'f<Hda');
  text = text.replace(/ඵ්‍යෝ/g, 'fMHda');
  text = text.replace(/ඨ්‍යෝ/g, 'fGHda');
  text = text.replace(/ශ්‍යෝ/g, 'fYHda');
  text = text.replace(/ක්‍ෂ්‍යෝ/g, 'fÌHda');
  text = text.replace(/බ්‍යෝ/g, 'fnHda');
  text = text.replace(/ච්‍යෝ/g, 'fpHda');
  text = text.replace(/ඩ්‍යෝ/g, 'fâHda');
  text = text.replace(/ෆ්‍යෝ/g, 'f*Hda');
  text = text.replace(/ග්‍යෝ/g, 'f.Hda');
  text = text.replace(/ජ්‍යෝ/g, 'fcHda');
  text = text.replace(/ක්‍යෝ/g, 'flHda');
  text = text.replace(/ල්‍යෝ/g, 'f,Hda');
  text = text.replace(/ම්‍යෝ/g, 'fuHda');
  text = text.replace(/න්‍යෝ/g, 'fkHda');
  text = text.replace(/ප්‍යෝ/g, 'fmHda');
  text = text.replace(/ද්‍යෝ/g, 'foHda');
  text = text.replace(/ස්‍යෝ/g, 'fiHda');
  text = text.replace(/ට්‍යෝ/g, 'fgHda');
  text = text.replace(/ව්‍යෝ/g, 'fjHda');
  text = text.replace(/ත්‍යෝ/g, 'f;Hda');
  text = text.replace(/භ්‍යෝ/g, 'fNHda');
  text = text.replace(/ධ්‍යෝ/g, 'fOHda');
  text = text.replace(/ථ්‍යෝ/g, 'f:Hda');
  text = text.replace(/ෂ්‍යො/g, 'fIHd');
  text = text.replace(/ශ්‍යො/g, 'fYHd');
  text = text.replace(/ඛ්‍යො/g, 'fLHd');
  text = text.replace(/ක්‍ෂ්‍යො/g, 'fÌHd');
  text = text.replace(/බ්‍යො/g, 'fnHd');
  text = text.replace(/ව්‍යො/g, 'fjHd');
  text = text.replace(/ඩ්‍යො/g, 'fvHd');
  text = text.replace(/ෆ්‍යො/g, 'f*Hd');
  text = text.replace(/ග්‍යො/g, 'f.Hd');
  text = text.replace(/ජ්‍යො/g, 'fcHd');
  text = text.replace(/ක්‍යො/g, 'flHd');
  text = text.replace(/ම්‍යො/g, 'fuHd');
  text = text.replace(/ප්‍යො/g, 'fmHd');
  text = text.replace(/ද්‍යො/g, 'foHd');
  text = text.replace(/ස්‍යො/g, 'fiHd');
  text = text.replace(/ට්‍යො/g, 'fgHd');
  text = text.replace(/ව්‍යො/g, 'fjHd');
  text = text.replace(/ත්‍යො/g, 'f;Hd');
  text = text.replace(/භ්‍යො/g, 'fNHd');
  text = text.replace(/ධ්‍යො/g, 'fOHd');
  text = text.replace(/ථ්‍යො/g, 'f:Hd');
  text = text.replace(/ෂ්‍යෙ/g, 'fIH');
  text = text.replace(/ඡ්‍යෙ/g, 'fPH');
  text = text.replace(/ළ්‍යෙ/g, 'f<H');
  text = text.replace(/ණ්‍යෙ/g, 'fKH');
  text = text.replace(/ච්‍යෙ/g, 'fpH');
  text = text.replace(/ල්‍යෙ/g, 'f,H');
  text = text.replace(/න්‍යෙ/g, 'fkH');
  text = text.replace(/ශ්‍යෙ/g, 'fYH');
  text = text.replace(/ඛ්‍යෙ/g, 'fLH');
  text = text.replace(/ක්‍ෂ්යෙ/g, 'fÌH');
  text = text.replace(/බ්‍යෙ/g, 'fnH');
  text = text.replace(/ඩ්‍යෙ/g, 'fvH');
  text = text.replace(/ෆ්‍යෙ/g, 'f*H');
  text = text.replace(/ග්‍යෙ/g, 'f.H');
  text = text.replace(/ජ්‍යෙ/g, 'fcH');
  text = text.replace(/ක්‍යෙ/g, 'flH');
  text = text.replace(/ම්‍යෙ/g, 'fuH');
  text = text.replace(/ප්‍යෙ/g, 'fmH');
  text = text.replace(/ද්‍යෙ/g, 'foH');
  text = text.replace(/ස්‍යෙ/g, 'fiH');
  text = text.replace(/ට්‍යෙ/g, 'fgH');
  text = text.replace(/ව්‍යෙ/g, 'fjH');
  text = text.replace(/ත්‍යෙ/g, 'f;H');
  text = text.replace(/භ්‍යෙ/g, 'fNH');
  text = text.replace(/ධ්‍යෙ/g, 'fOH');
  text = text.replace(/ථ්‍යෙ/g, 'f:H');
  text = text.replace(/ෂ්‍රෝ/g, 'fI%da');
  text = text.replace(/ඝ්‍රෝ/g, 'f>%da');
  text = text.replace(/ශ්‍රෝ/g, 'fY%da');
  text = text.replace(/ක්‍ෂ්‍රෝ/g, 'fÌ%da');
  text = text.replace(/බ්‍රෝ/g, 'fn%da');
  text = text.replace(/ඩ්‍රෝ/g, 'fv%da');
  text = text.replace(/ෆ්‍රෝ/g, 'f*%da');
  text = text.replace(/ග්‍රෝ/g, 'f.%da');
  text = text.replace(/ක්‍රෝ/g, 'fl%da');
  text = text.replace(/ප්‍රෝ/g, 'fm%da');
  text = text.replace(/ද්‍රෝ/g, 'føda');
  text = text.replace(/ස්‍රෝ/g, 'fi%da');
  text = text.replace(/ට්‍රෝ/g, 'fg%da');
  text = text.replace(/ත්‍රෝ/g, 'f;%da');
  text = text.replace(/ශ්‍රො/g, 'fY%d');
  text = text.replace(/ඩ්‍රො/g, 'fv%d');
  text = text.replace(/ෆ්‍රො/g, 'f*%d');
  text = text.replace(/ග්‍රො/g, 'f.%d');
  text = text.replace(/ක්‍රො/g, 'fl%d');
  text = text.replace(/ප්‍රො/g, 'fm%d');
  text = text.replace(/ද්‍රො/g, 'fød');
  text = text.replace(/ස්‍රො/g, 'fi%d');
  text = text.replace(/ට්‍රො/g, 'fg%d');
  text = text.replace(/ත්‍රො/g, 'f;%d');
  text = text.replace(/ශ්‍රේ/g, 'fYa%');
  text = text.replace(/බ්‍රේ/g, 'fí%');
  text = text.replace(/ඩ්‍රේ/g, 'fâ%');
  text = text.replace(/ෆ්‍රේ/g, 'f*a%');
  text = text.replace(/ග්‍රේ/g, 'f.a%');
  text = text.replace(/ක්‍රේ/g, 'fla%');
  text = text.replace(/ප්‍රේ/g, 'fma%');
  text = text.replace(/ද්‍රේ/g, 'føa');
  text = text.replace(/ස්‍රේ/g, 'fia%');
  text = text.replace(/ත්‍රේ/g, 'f;a%');
  text = text.replace(/ධ්‍රේ/g, 'fè%');
  text = text.replace(/ෂ්‍රෙ/g, 'fI%');
  text = text.replace(/ශ්‍රෙ/g, 'fY%');
  text = text.replace(/බ්‍රෙ/g, 'fn%');
  text = text.replace(/ෆ්‍රෙ/g, 'f*%');
  text = text.replace(/ග්‍රෙ/g, 'f.%');
  text = text.replace(/ක්‍රෙ/g, 'fl%');
  text = text.replace(/ප්‍රෙ/g, 'fm%');
  text = text.replace(/ද්‍රෙ/g, 'fø');
  text = text.replace(/ස්‍රෙ/g, 'fi%');
  text = text.replace(/ත්‍රෙ/g, 'f;%');
  text = text.replace(/භ්‍රෙ/g, 'fN%');
  text = text.replace(/ධ්‍රෙ/g, 'fO%');
  text = text.replace(/්‍ය/g, 'H');
  text = text.replace(/්‍ර/g, '%');
  text = text.replace(/ෂෞ/g, 'fI!');
  text = text.replace(/ඡෞ/g, 'fP!');
  text = text.replace(/ශෞ/g, 'fY!');
  text = text.replace(/බෞ/g, 'fn!');
  text = text.replace(/චෞ/g, 'fp!');
  text = text.replace(/ඩෞ/g, 'fv!');
  text = text.replace(/ෆෞ/g, 'f*!');
  text = text.replace(/ගෞ/g, 'f.!');
  text = text.replace(/ජෞ/g, 'fc!');
  text = text.replace(/කෞ/g, 'fl!');
  text = text.replace(/ලෞ/g, 'f,!');
  text = text.replace(/මෞ/g, 'fu!');
  text = text.replace(/නෞ/g, 'fk!');
  text = text.replace(/පෞ/g, 'fm!');
  text = text.replace(/දෞ/g, 'fo!');
  text = text.replace(/රෞ/g, 'fr!');
  text = text.replace(/සෞ/g, 'fi!');
  text = text.replace(/ටෞ/g, 'fg!');
  text = text.replace(/තෞ/g, 'f;!');
  text = text.replace(/භෞ/g, 'fN!');
  text = text.replace(/ඤෞ/g, 'f[!');
  text = text.replace(/ෂෝ/g, 'fIda');
  text = text.replace(/ඹෝ/g, 'fUda');
  text = text.replace(/ඡෝ/g, 'fPda');
  text = text.replace(/ඪෝ/g, 'fVda');
  text = text.replace(/ඝෝ/g, 'f>da');
  text = text.replace(/ඛෝ/g, 'fLda');
  text = text.replace(/ළෝ/g, 'f<da');
  text = text.replace(/ඟෝ/g, 'fÛda');
  text = text.replace(/ණෝ/g, 'fKda');
  text = text.replace(/ඵෝ/g, 'fMda');
  text = text.replace(/ඨෝ/g, 'fGda');
  text = text.replace(/ඬෝ/g, 'fËda');
  text = text.replace(/ශෝ/g, 'fYda');
  text = text.replace(/ඥෝ/g, 'f{da');
  text = text.replace(/ඳෝ/g, 'f|da');
  text = text.replace(/ක්‍ෂෝ/g, 'fÌda');
  text = text.replace(/බෝ/g, 'fnda');
  text = text.replace(/චෝ/g, 'fpda');
  text = text.replace(/ඩෝ/g, 'fvda');
  text = text.replace(/ෆෝ/g, 'f*da');
  text = text.replace(/ගෝ/g, 'f.da');
  text = text.replace(/හෝ/g, 'fyda');
  text = text.replace(/ජෝ/g, 'fcda');
  text = text.replace(/කෝ/g, 'flda');
  text = text.replace(/ලෝ/g, 'f,da');
  text = text.replace(/මෝ/g, 'fuda');
  text = text.replace(/නෝ/g, 'fkda');
  text = text.replace(/පෝ/g, 'fmda');
  text = text.replace(/දෝ/g, 'foda');
  text = text.replace(/රෝ/g, 'frda');
  text = text.replace(/සෝ/g, 'fida');
  text = text.replace(/ටෝ/g, 'fgda');
  text = text.replace(/වෝ/g, 'fjda');
  text = text.replace(/තෝ/g, 'f;da');
  text = text.replace(/භෝ/g, 'fNda');
  text = text.replace(/යෝ/g, 'fhda');
  text = text.replace(/ඤෝ/g, 'f[da');
  text = text.replace(/ධෝ/g, 'fOda');
  text = text.replace(/ථෝ/g, 'f:da');
  text = text.replace(/ෂො/g, 'fId');
  text = text.replace(/ඹො/g, 'fUd');
  text = text.replace(/ඡො/g, 'fPd');
  text = text.replace(/ඪො/g, 'fVd');
  text = text.replace(/ඝො/g, 'f>d');
  text = text.replace(/ඛො/g, 'fLd');
  text = text.replace(/ළො/g, 'f<d');
  text = text.replace(/ඟො/g, 'fÕd');
  text = text.replace(/ණො/g, 'fKd');
  text = text.replace(/ඵො/g, 'fMd');
  text = text.replace(/ඨො/g, 'fGd');
  text = text.replace(/ඬො/g, 'fËd');
  text = text.replace(/ශො/g, 'fYd');
  text = text.replace(/ඥො/g, 'f{d');
  text = text.replace(/ඳො/g, 'f|d');
  text = text.replace(/ක්‍ෂො/g, 'fÌd');
  text = text.replace(/බො/g, 'fnd');
  text = text.replace(/චො/g, 'fpd');
  text = text.replace(/ඩො/g, 'fvd');
  text = text.replace(/ෆො/g, 'f*d');
  text = text.replace(/ගො/g, 'f.d');
  text = text.replace(/හො/g, 'fyd');
  text = text.replace(/ජො/g, 'fcd');
  text = text.replace(/කො/g, 'fld');
  text = text.replace(/ලො/g, 'f,d');
  text = text.replace(/මො/g, 'fud');
  text = text.replace(/නො/g, 'fkd');
  text = text.replace(/පො/g, 'fmd');
  text = text.replace(/දො/g, 'fod');
  text = text.replace(/රො/g, 'frd');
  text = text.replace(/සො/g, 'fid');
  text = text.replace(/ටො/g, 'fgd');
  text = text.replace(/වො/g, 'fjd');
  text = text.replace(/තො/g, 'f;d');
  text = text.replace(/භො/g, 'fNd');
  text = text.replace(/යො/g, 'fhd');
  text = text.replace(/ඤො/g, 'f[d');
  text = text.replace(/ධො/g, 'fOd');
  text = text.replace(/ථො/g, 'f:d');
  text = text.replace(/ෂේ/g, 'fIa');
  text = text.replace(/ඹේ/g, 'fò');
  text = text.replace(/ඡේ/g, 'fþ');
  text = text.replace(/ඪේ/g, 'fa');
  text = text.replace(/ඝේ/g, 'f>a');
  text = text.replace(/ඛේ/g, 'fÄ');
  text = text.replace(/ළේ/g, 'f<a');
  text = text.replace(/ඟේ/g, 'fÛa');
  text = text.replace(/ණේ/g, 'fKa');
  text = text.replace(/ඵේ/g, 'fMa');
  text = text.replace(/ඨේ/g, 'fGa');
  text = text.replace(/ඬේ/g, 'få');
  text = text.replace(/ශේ/g, 'fYa');
  text = text.replace(/ඥේ/g, 'f{a');
  text = text.replace(/ඳේ/g, 'f|a');
  text = text.replace(/ක්‍ෂේ/g, 'fÌa');
  text = text.replace(/බේ/g, 'fí');
  text = text.replace(/චේ/g, 'fÉ');
  text = text.replace(/ඩේ/g, 'fâ');
  text = text.replace(/ෆේ/g, 'f*');
  text = text.replace(/ගේ/g, 'f.a');
  text = text.replace(/හේ/g, 'fya');
  text = text.replace(/පේ/g, 'fma');
  text = text.replace(/කේ/g, 'fla');
  text = text.replace(/ලේ/g, 'f,a');
  text = text.replace(/මේ/g, 'fï');
  text = text.replace(/නේ/g, 'fka');
  text = text.replace(/ජේ/g, 'f–');
  text = text.replace(/දේ/g, 'foa');
  text = text.replace(/රේ/g, 'f¾');
  text = text.replace(/සේ/g, 'fia');
  text = text.replace(/ටේ/g, 'fÜ');
  text = text.replace(/වේ/g, 'fõ');
  text = text.replace(/තේ/g, 'f;a');
  text = text.replace(/භේ/g, 'fNa');
  text = text.replace(/යේ/g, 'fha');
  text = text.replace(/ඤේ/g, 'f[a');
  text = text.replace(/ධේ/g, 'fè');
  text = text.replace(/ථේ/g, 'f:a');
  text = text.replace(/ෂෙ/g, 'fI');
  text = text.replace(/ඹෙ/g, 'fU');
  text = text.replace(/ඓ/g, 'ft');
  text = text.replace(/ඡෙ/g, 'fP');
  text = text.replace(/ඪෙ/g, 'fV');
  text = text.replace(/ඝෙ/g, 'f>');
  text = text.replace(/ඛෙ/g, 'fn');
  text = text.replace(/ළෙ/g, 'f<');
  text = text.replace(/ඟෙ/g, 'fÛ');
  text = text.replace(/ණෙ/g, 'fK');
  text = text.replace(/ඵෙ/g, 'fM');
  text = text.replace(/ඨෙ/g, 'fG');
  text = text.replace(/ඬෙ/g, 'fË');
  text = text.replace(/ශෙ/g, 'fY');
  text = text.replace(/ඥෙ/g, 'f{');
  text = text.replace(/ඳෙ/g, 'fË');
  text = text.replace(/ක්‍ෂෙ/g, 'fÌ');
  text = text.replace(/බෙ/g, 'fn');
  text = text.replace(/චෙ/g, 'fp');
  text = text.replace(/ඩෙ/g, 'fv');
  text = text.replace(/ෆෙ/g, 'f*');
  text = text.replace(/ගෙ/g, 'f.');
  text = text.replace(/හෙ/g, 'fy');
  text = text.replace(/ජෙ/g, 'fc');
  text = text.replace(/කෙ/g, 'fl');
  text = text.replace(/ලෙ/g, 'f,');
  text = text.replace(/මෙ/g, 'fu');
  text = text.replace(/නෙ/g, 'fk');
  text = text.replace(/පෙ/g, 'fm');
  text = text.replace(/දෙ/g, 'fo');
  text = text.replace(/රෙ/g, 'fr');
  text = text.replace(/සෙ/g, 'fi');
  text = text.replace(/ටෙ/g, 'fg');
  text = text.replace(/වෙ/g, 'fj');
  text = text.replace(/තෙ/g, 'f;');
  text = text.replace(/භෙ/g, 'fN');
  text = text.replace(/යෙ/g, 'fh');
  text = text.replace(/ඤෙ/g, 'f[');
  text = text.replace(/ධෙ/g, 'fO');
  text = text.replace(/ථෙ/g, 'f:');
  text = text.replace(/තු/g, ';=');
  text = text.replace(/ගු/g, '.=');
  text = text.replace(/කු/g, 'l=');
  text = text.replace(/තූ/g, ';+');
  text = text.replace(/ගූ/g, '.+');
  text = text.replace(/කූ/g, 'l+');
  text = text.replace(/රු/g, 're');
  text = text.replace(/රූ/g, 'rE');
  text = text.replace(/ආ/g, 'wd');
  text = text.replace(/ඇ/g, 'we');
  text = text.replace(/ඈ/g, 'wE');
  text = text.replace(/ඌ/g, 'W!');
  text = text.replace(/ඖ/g, 'T!');
  text = text.replace(/ඒ/g, 'ta');
  text = text.replace(/ඕ/g, '´');
  text = text.replace(/ඳි/g, '¢');
  text = text.replace(/ඳී/g, '£');
  text = text.replace(/දූ/g, '¥');
  text = text.replace(/දී/g, '§');
  text = text.replace(/ලූ/g, '¨');
  text = text.replace(/ර්‍ය/g, '©');
  text = text.replace(/ඳූ/g, 'ª');
  text = text.replace(/ර්/g, '¾');
  text = text.replace(/ඨි/g, 'À');
  text = text.replace(/ඨී/g, 'Á');
  text = text.replace(/ඡී/g, 'Â');
  text = text.replace(/ඛ්/g, 'Ä');
  text = text.replace(/ඛි/g, 'Å');
  text = text.replace(/ලු/g, 'Æ');
  text = text.replace(/ඛී/g, 'Ç');
  text = text.replace(/දි/g, 'È');
  text = text.replace(/ච්/g, 'É');
  text = text.replace(/ජ්/g, 'Ê');
  text = text.replace(/රී/g, 'Í');
  text = text.replace(/ඪී/g, 'Î');
  text = text.replace(/ඪී/g, 'Ð,');
  text = text.replace(/චි/g, 'Ñ');
  text = text.replace(/ථී/g, 'Ò');
  text = text.replace(/ථී/g, 'Ó');
  text = text.replace(/ජී/g, 'Ô');
  text = text.replace(/චී/g, 'Ö');
  text = text.replace(/ඞ්/g, 'Ù');
  text = text.replace(/ඵී/g, 'Ú');
  text = text.replace(/ට්/g, 'Ü');
  text = text.replace(/ඵි/g, 'Ý');
  text = text.replace(/රි/g, 'ß');
  text = text.replace(/ටී/g, 'à');
  text = text.replace(/ටි/g, 'á');
  text = text.replace(/ඩ්/g, 'â');
  text = text.replace(/ඩී/g, 'ã');
  text = text.replace(/ඩි/g, 'ä');
  text = text.replace(/ඬ්/g, 'å');
  text = text.replace(/ඬි/g, 'ç');
  text = text.replace(/ධ්/g, 'è');
  text = text.replace(/ඬී/g, 'é');
  text = text.replace(/ධි/g, 'ê');
  text = text.replace(/ධී/g, 'ë');
  text = text.replace(/බි/g, 'ì');
  text = text.replace(/බ්/g, 'í');
  text = text.replace(/බී/g, 'î');
  text = text.replace(/ම්/g, 'ï');
  text = text.replace(/ජි/g, 'ð');
  text = text.replace(/මි/g, 'ñ');
  text = text.replace(/ඹ්/g, 'ò');
  text = text.replace(/මී/g, 'ó');
  text = text.replace(/ඹි/g, 'ô');
  text = text.replace(/ව්/g, 'õ');
  text = text.replace(/ඹී/g, 'ö');
  text = text.replace(/ඳු/g, '÷');
  text = text.replace(/ද්‍ර/g, 'ø');
  text = text.replace(/වී/g, 'ù');
  text = text.replace(/වි/g, 'ú');
  text = text.replace(/ඞ්/g, 'û');
  text = text.replace(/ඞී/g, 'ü');
  text = text.replace(/ඡි/g, 'ý');
  text = text.replace(/ඡ්/g, 'þ');
  text = text.replace(/දු/g, 'ÿ');
  text = text.replace(/ජ්/g, '–');
  text = text.replace(/ර්‍ණ/g, '“');
  text = text.replace(/ණී/g, '”');
  text = text.replace(/ජී/g, '„');
  text = text.replace(/ඡි/g, '‰');
  text = text.replace(/ඩි/g, '');
  text = text.replace(/ඤු/g, '™');
  text = text.replace(/ග/g, '.');
  text = text.replace(/ළු/g, '¿');
  text = text.replace(/ෂ/g, 'I');
  text = text.replace(/ං/g, 'x');
  text = text.replace(/ඃ/g, '#');
  text = text.replace(/ඹ/g, 'U');
  text = text.replace(/ඡ/g, 'P');
  text = text.replace(/ඪ/g, 'V');
  text = text.replace(/ඝ/g, '>');
  text = text.replace(/ඊ/g, 'B');
  text = text.replace(/ඣ/g, 'CO');
  text = text.replace(/ඛ/g, 'L');
  text = text.replace(/ළ/g, '<');
  text = text.replace(/ඟ/g, 'Û');
  text = text.replace(/ණ/g, 'K');
  text = text.replace(/ඵ/g, 'M');
  text = text.replace(/ඨ/g, 'G');
  text = text.replace(/ඃ/g, '#');
  text = text.replace(/\"/g, ',');
  text = text.replace(/\//g, '$');
  text = text.replace(/\)/g, '&');
  text = text.replace(/:/g, '(');
  text = text.replace(/-/g, ')');
  text = text.replace(/ෆ/g, '*');
  text = text.replace(/ල/g, ',');
  text = text.replace(/-/g, '-');
  text = text.replace(/රැ/g, '/');
  text = text.replace(/ථ/g, ':');
  text = text.replace(/ත/g, ';');
  text = text.replace(/ළ/g, '<');
  text = text.replace(/ඝ/g, '>');
  text = text.replace(/රෑ/g, '?');
  text = text.replace(/ඊ/g, 'B');
  text = text.replace(/ක‍/g, 'C');
  text = text.replace(/‍ෘ/g, 'D');
  text = text.replace(/ෑ/g, 'E');
  text = text.replace(/ත‍/g, 'F');
  text = text.replace(/ඨ/g, 'G');
  text = text.replace(/්‍ය/g, 'H');
  text = text.replace(/ෂ/g, 'I');
  text = text.replace(/න‍/g, 'J');
  text = text.replace(/ණ/g, 'K');
  text = text.replace(/ඛ/g, 'L');
  text = text.replace(/ඵ/g, 'M');
  text = text.replace(/භ/g, 'N');
  text = text.replace(/ධ/g, 'O');
  text = text.replace(/ඡ/g, 'P');
  text = text.replace(/ඍ/g, 'R');
  text = text.replace(/ඔ/g, 'T');
  text = text.replace(/ඹ/g, 'U');
  text = text.replace(/ඪ/g, 'V');
  text = text.replace(/උ/g, 'W');
  text = text.replace(/ශ/g, 'Y');
  text = text.replace(/ඤ/g, '[');
  text = text.replace(/ඉ/g, 'b');
  text = text.replace(/ජ/g, 'c');
  text = text.replace(/ට/g, 'g');
  text = text.replace(/ය/g, 'h');
  text = text.replace(/ස/g, 'i');
  text = text.replace(/ව/g, 'j');
  text = text.replace(/න/g, 'k');
  text = text.replace(/ක/g, 'l');
  text = text.replace(/ප/g, 'm');
  text = text.replace(/බ/g, 'n');
  text = text.replace(/ද/g, 'o');
  text = text.replace(/ච/g, 'p');
  text = text.replace(/ර/g, 'r');
  text = text.replace(/එ/g, 't');
  text = text.replace(/ම/g, 'u');
  text = text.replace(/ඩ/g, 'v');
  text = text.replace(/අ/g, 'w');
  text = text.replace(/හ/g, 'y');
  text = text.replace(/ඥ/g, '{');
  text = text.replace(/ඳ/g, '|');
  text = text.replace(/ක්‍ෂ/g, 'Ì');
  text = text.replace(/ැ/g, 'e');
  text = text.replace(/ෑ/g, 'E');
  text = text.replace(/ෙ/g, 'f');
  text = text.replace(/ු/g, 'q');
  text = text.replace(/ි/g, 's');
  text = text.replace(/ූ/g, 'Q');
  text = text.replace(/ී/g, 'S');
  text = text.replace(/ෘ/g, 'D');
  text = text.replace(/ෲ/g, 'DD');
  text = text.replace(/ෟ/g, '!');
  text = text.replace(/ා/g, 'd');
  text = text.replace(/්/g, 'a');
  text = text.replace(/￦/g, '"');
  text = text.replace(/�/g, "'");
  text = text.replace(/￫/g, '^');
  text = text.replace(/￩/g, '&');
  text = text.replace(/ￔ/g, ')');
  text = text.replace(/ￓ/g, '@');
  text = text.replace(/ￒ/g, '`');
  text = text.replace(/ￏ/g, '}');
  text = text.replace(/ￎ/g, '~');
  text = text.replace(/\ￍ/g, '¤');
  text = text.replace(/\ￌ/g, '•');
  text = text.replace(/\ￊ/g, '›');
  text = text.replace(/\ﾶ/g, '∙');
  text = text.replace(/ￕ/g, ']');
  return text;
};

module.exports = { fmToUnicode, unicodeToDL, dlToUnicode };

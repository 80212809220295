import { takeLatest, put, call } from 'redux-saga/effects';
import { unicodeToDL, dlToUnicode } from '@geniussoft/unicode-tools';
import axios from '../../config/axios';
import {
  FETCH_ITEM_DATA,
  FETCH_ITEM_FAIL,
  fetItemDataSuccess,
  fetchItemFail,
  ADD_ITEM,
  addItemSuccess,
  fetchItemData,
  EDIT_ITEM,
  editItemSuccess,
  DELETE_ITEM,
  deleteItemSuccess,
} from '../reducers/itemReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

const transformToSinhala = (item) => {
  const transformedItem = { ...item };
  transformedItem.itemNameSin = unicodeToDL(transformedItem.itemNameSin);
  return transformedItem;
};

function* addItem({ item, setSubmitting, resetForm }) {
  try {
    const response = yield call(axios.post, 'items', transformToSinhala(item));
    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(addItemSuccess(response));
        yield put(setSnackNotifyMessage('Item Added Successfully !', 'success'));
        yield put(fetchItemData());
      } else {
        yield put(setSnackNotifyMessage('Item Barcode or Barcodes Already Exists !', 'error'));

        yield put(fetchItemFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The Item !', 'error'));

      yield put(fetchItemFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Item !', 'error'));

    yield put(fetchItemFail(error));
  }
}

export function* addItemSaga() {
  try {
    yield takeLatest(ADD_ITEM, addItem);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Item !', 'error'));

    yield put(fetchItemFail(error));
  }
}

function* fetchItemsData() {
  try {
    const response = yield call(axios.get, 'items');
    if (response.data && response.data.data) {
      const data = response.data.data.map((item) => ({ ...item, itemNameSin: dlToUnicode(item.itemNameSin) }));
      yield put(fetItemDataSuccess(data));
    } else {
      yield put(fetchItemFail(FETCH_ITEM_FAIL));
    }
  } catch (error) {
    yield put(fetchItemFail(FETCH_ITEM_FAIL, error));
  }
}

export function* fetchItemsSaga() {
  try {
    yield takeLatest(FETCH_ITEM_DATA, fetchItemsData);
  } catch (error) {
    yield put(fetchItemFail(FETCH_ITEM_FAIL, error));
  }
}

function* editItem({ item }) {
  const { itemCode } = item;
  const updatedItem = {
    ...item,
  };
  delete updatedItem.itemCode;

  try {
    const response = yield call(axios.put, `/items/${itemCode}`, transformToSinhala(updatedItem));
    if (response) {
      if (response.data.success === true) {
        yield put(editItemSuccess(response));
        yield put(setSnackNotifyMessage('Item Updated Successfully !', 'success'));

        yield put(fetchItemData());
      } else {
        yield put(setSnackNotifyMessage('Item Barcode or Barcodes Already Exists !', 'error'));

        yield put(fetchItemFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Updating The Item !', 'error'));

      yield put(fetchItemFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Item !', 'error'));

    yield put(fetchItemFail(error));
  }
}

export function* editItemSaga() {
  try {
    yield takeLatest(EDIT_ITEM, editItem);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Item !', 'error'));

    yield put(fetchItemFail(error));
  }
}

function* deleteItem({ itemCode }) {
  try {
    const response = yield call(axios.put, `items?itemCode=${itemCode}`);
    if (response) {
      if (response.data.success === true) {
        yield put(deleteItemSuccess(response));
        yield put(setSnackNotifyMessage('Item Deactivated Successfully !', 'success'));

        yield put(fetchItemData());
      } else {
        yield put(setSnackNotifyMessage('Error Deactivating The Item !', 'error'));
        yield put(fetchItemFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Deactivating The Item !', 'error'));
      yield put(fetchItemFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Deactivating The Item !', 'error'));
    yield put(fetchItemFail(error));
  }
}

export function* deleteItemSaga() {
  try {
    yield takeLatest(DELETE_ITEM, deleteItem);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Deactivating The Item !', 'error'));

    yield put(fetchItemFail(error));
  }
}

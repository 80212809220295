import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_CUSTOMER_DATA,
  FETCH_CUSTOMER_ACCOUNTS,
  fetchCustomerTableDataSuccess,
  fetchCustomerAccountsDataSuccess,
  fetchDataFail,
  ADD_CUSTOMER,
  addCustomerSuccess,
  fetchCustomerData,
  EDIT_CUSTOMER,
  editCustomerSuccess,
} from '../reducers/customerReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* addCustomer({ customer, setSubmitting, resetForm }) {
  try {
    const response = yield call(axios.post, 'customers', customer);
    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(addCustomerSuccess(response));
        yield put(setSnackNotifyMessage('Customer Added Successfully !', 'success'));
        yield put(fetchCustomerData());
      } else {
        yield put(setSnackNotifyMessage('Loyalty Number Already Exists !', 'error'));
        setSubmitting(false);
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The Customer !', 'error'));
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Customer !', 'error'));
    yield put(fetchDataFail(error));
  }
}

export function* addCustomerSaga() {
  try {
    yield takeLatest(ADD_CUSTOMER, addCustomer);
  } catch (error) {
    yield put(fetchDataFail(error));
    yield put(setSnackNotifyMessage('Error Adding The Customer !', 'error'));
  }
}

function* fetchCustomer() {
  try {
    const response = yield call(axios.get, 'customers');
    if (response) {
      if (response.data.success === true) {
        yield put(fetchCustomerTableDataSuccess(response.data.data));
      } else {
        yield put(fetchDataFail());
      }
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchCustomerSaga() {
  try {
    yield takeLatest(FETCH_CUSTOMER_DATA, fetchCustomer);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchCustomerAccountData({ customer }) {
  const { customerId } = customer;
  try {
    const response = yield call(axios.get, `customeraccounts/${customerId}`);
    if (response.data.data) {
      yield put(fetchCustomerAccountsDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchCustomerAccountDataSaga() {
  try {
    yield takeLatest(FETCH_CUSTOMER_ACCOUNTS, fetchCustomerAccountData);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* editCustomerData({ customer }) {
  const { customerId } = customer;
  const updatedCustomer = { ...customer };
  delete updatedCustomer.customerId;
  try {
    const response = yield call(axios.put, `/customers/${customerId}`, updatedCustomer);
    if (response) {
      if (response.data.success === true) {
        yield put(editCustomerSuccess(response));
        yield put(setSnackNotifyMessage('Customer Updated Successfully !', 'success'));
        yield put(fetchCustomerData());
      } else {
        yield put(setSnackNotifyMessage('Loyalty Number Already Exists !', 'error'));
        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Updating The Customer !', 'error'));
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Customer !', 'error'));
    yield put(fetchDataFail(error));
  }
}

export function* editCustomerDataSaga() {
  try {
    yield takeLatest(EDIT_CUSTOMER, editCustomerData);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Customer !', 'error'));
    yield put(fetchDataFail(error));
  }
}

import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_CURRENT_STOCK,
  fetchCurrentStockSuccess,
  fetchDataFail,
  ADD_STOCK,
  addStockSuccess,
  FETCH_STOCK,
  fetchStockSuccess,
  fetchStock,
} from '../reducers/stockBalanceReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* fetchCurrentStocks() {
  try {
    const response = yield call(axios.get, 'reports/currentstock');
    if (response.data.success === true) {
      yield put(fetchCurrentStockSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchCurrentStockSaga() {
  try {
    yield takeLatest(FETCH_CURRENT_STOCK, fetchCurrentStocks);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* addStocks({ balancedStock }) {
  try {
    const response = yield call(axios.post, 'stockbalances', balancedStock);
    if (response) {
      if (response.data.success === true) {
        yield put(addStockSuccess(response));
        yield put(setSnackNotifyMessage('New Stock Added Successfully!', 'success'));

        yield put(fetchStock());
        // yield put(fetchCurrentStock());
      } else {
        yield put(setSnackNotifyMessage('Error Adding The Stock!', 'error'));

        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The Stock!', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Stock!', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* addStockSaga() {
  try {
    yield takeLatest(ADD_STOCK, addStocks);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Stock!', 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchStockData() {
  try {
    const response = yield call(axios.get, 'stockbalances');
    if (response.data.success === true) {
      yield put(fetchStockSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchStockDataSaga() {
  try {
    yield takeLatest(FETCH_STOCK, fetchStockData);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

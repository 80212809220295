import { combineReducers, compose, applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {
  itemReducer,
  dashboardReducer,
  customerReducer,
  supplierReducer,
  categoryReducer,
  userReducer,
  grnReducer,
  stockBalanceReducer,
  cardPaymentReducer,
  cashierWiseInvoiceSummaryReducer,
  customerChequePaymentsReducer,
  customerFreeIssuesReducer,
  customerOutstandingSummaryReducer,
  dailySalesReducer,
  itemWiseProfitReducer,
  supplierChequePaymentsReducer,
  supplierFreeIssueReducer,
  supplierWisePurchaseReducer,
  cashierWiseInvoicesReducer,
  stockMovementReducer,
  itemPriseFluctuationReducer,
  dayEndSummaryReducer,
  invoiceSummaryReducer,
  currentStockReducer,
  salesReducer,
  snackReducer,
  monthlySalesReducer,
  grnHistoryReducer,
  invoiceHistoryReducer,
  supplierReturnHistoryReducer,
  fastMovingItemsReducer,
  mobileWalletPaymentReducer,
  salesAssistantWiseIncentiveReducer,
  voidInvoiceSummaryReducer,
} from './reducers';
import initSagas from './initSagas';

const getStore = () => {
  const reducers = combineReducers({
    items: itemReducer,
    dashboard: dashboardReducer,
    customers: customerReducer,
    suppliers: supplierReducer,
    category: categoryReducer,
    users: userReducer,
    grn: grnReducer,
    stocks: stockBalanceReducer,
    cardPayment: cardPaymentReducer,
    cashierWiseInvoices: cashierWiseInvoiceSummaryReducer,
    customerChequePayments: customerChequePaymentsReducer,
    customerFreeIssues: customerFreeIssuesReducer,
    customerOutstandingSummary: customerOutstandingSummaryReducer,
    dailySales: dailySalesReducer,
    itemWiseProfits: itemWiseProfitReducer,
    supplierChequePayments: supplierChequePaymentsReducer,
    supplierFreeIssues: supplierFreeIssueReducer,
    supplierWisePurchases: supplierWisePurchaseReducer,
    cashierWiseInvoice: cashierWiseInvoicesReducer,
    stockMovements: stockMovementReducer,
    itemPriceFluctuation: itemPriseFluctuationReducer,
    dayEndSummary: dayEndSummaryReducer,
    invoiceSummary: invoiceSummaryReducer,
    currentStocks: currentStockReducer,
    sales: salesReducer,
    notification: snackReducer,
    monthlySales: monthlySalesReducer,
    grnHistory: grnHistoryReducer,
    invoiceHistory: invoiceHistoryReducer,
    supplierReturnHistory: supplierReturnHistoryReducer,
    fastMovingItems: fastMovingItemsReducer,
    mobileWalletPayment: mobileWalletPaymentReducer,
    salesAssistant: salesAssistantWiseIncentiveReducer,
    voidInvoiceSummary: voidInvoiceSummaryReducer,
  });

  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(reducers, composeEnhancers(...enhancers));

  initSagas(sagaMiddleware);

  return store;
};

export default getStore;

export const SET_DATE = 'genius/history/supplierReturnHistory/SET_DATE';
export const SET_SUPPLIER_ID = 'genius/history/supplierReturnHistory/SET_SUPPLIER_ID';
export const SET_DETAIL = 'genius/history/supplierReturnHistory/SET_DETAIL';
export const SET_SHOP_ID = 'genius/history/supplierReturnHistory/SET_SHOP_ID';
export const RESET_DATA = 'genius/history/supplierReturnHistory/RESET_DATA';

export const FETCH_DATA = 'genius/history/supplierReturnHistory/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/history/supplierReturnHistory/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/history/supplierReturnHistory/FETCH_DATA_FAIL';

export const FETCH_SUP_RETURN_INVOICE_DATA = 'genius/history/supplierReturnHistory/FETCH_SUP_RETURN_INVOICE_DATA';
export const FETCH_SUP_RETURN_INVOICE_DATA_SUCCESS =
  'genius/history/supplierReturnHistory/FETCH_SUP_RETURN_INVOICE_DATA_SUCCESS';
export const FETCH_SUP_RETURN_INVOICE_DATA_FAIL =
  'genius/history/supplierReturnHistory/FETCH_SUP_RETURN_INVOICE_DATA_FAIL';

const initialState = {
  loading: false,
  detail: {},
  returnData: [],
  supplierReturnHistory: [],
  selectedDate: new Date(),
  supplierId: '0',
  shopId: '1111',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE:
      return { ...state, selectedDate: action.selectedDate, loading: false };

    case SET_SUPPLIER_ID:
      return { ...state, supplierId: action.supplierId, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, supplierReturnHistory: action.supplierReturnHistory, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, supplierReturnHistory: [] };
    case FETCH_SUP_RETURN_INVOICE_DATA:
      return { ...state, loading: true };
    case FETCH_SUP_RETURN_INVOICE_DATA_SUCCESS:
      return { ...state, returnData: action.returnData, loading: false };
    case FETCH_SUP_RETURN_INVOICE_DATA_FAIL:
      return { ...state, loading: false };
    case SET_DETAIL:
      return { ...state, loading: false, detail: action.detail };
    case RESET_DATA:
      return {
        ...state,
        selectedDate: initialState.selectedDate,
        supplierId: initialState.supplierId,
        shopId: initialState.shopId,
      };
    default:
      return state;
  }
};
export default reducer;

export const setDate = (selectedDate) => {
  return { type: SET_DATE, selectedDate };
};
export const setSupplierId = (supplierId) => {
  return { type: SET_SUPPLIER_ID, supplierId };
};

export const fetchData = (selectedDate, supplierId, shopId) => {
  return {
    type: FETCH_DATA,
    selectedDate,
    supplierId,
    shopId,
  };
};
export const fetchDataSuccess = (supplierReturnHistory) => {
  return { type: FETCH_DATA_SUCCESS, supplierReturnHistory };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const fetchSupplierReturnData = (supplierReturnId, returnDate, returnSupId, returnUserId) => {
  return { type: FETCH_SUP_RETURN_INVOICE_DATA, supplierReturnId, returnDate, returnSupId, returnUserId };
};
export const fetchSupplierReturnDataSuccess = (returnData) => {
  return { type: FETCH_SUP_RETURN_INVOICE_DATA_SUCCESS, returnData };
};
export const fetchSupplierReturnDataFail = (err) => {
  return { type: FETCH_SUP_RETURN_INVOICE_DATA_FAIL, err };
};
export const setDetail = (detail) => {
  return { type: SET_DETAIL, detail };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};
export const resetData = () => {
  return { type: RESET_DATA };
};

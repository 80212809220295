export const FETCH_CATEGORY_DATA = 'genius/item/FETCH_CATEGORY_DATA';
export const FETCH_CATEGORY_DATA_SUCCESS = 'genius/item/FETCH_CATEGORY_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/item/FETCH_DATA_FAIL';
export const ADD_CATEGORY = 'genius/item/ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'genius/item/ADD_CATEGORY_SUCCESS';
export const EDIT_CATEGORY = 'genius/item/EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'genius/item/EDIT_CATEGORY_SUCCESS';

const initialState = {
  loading: false,
  categoryData: [],
};

const categoryManagement = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_DATA:
      return { ...state, loading: true };
    case FETCH_CATEGORY_DATA_SUCCESS:
      return { ...state, categoryData: action.categoryData, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default categoryManagement;

export const fetchCategoryData = () => {
  return { type: FETCH_CATEGORY_DATA };
};
export const fetchCategoryDataSuccess = (categoryData) => {
  return { type: FETCH_CATEGORY_DATA_SUCCESS, categoryData };
};
export const fetchDataFail = (error) => {
  return { type: FETCH_DATA_FAIL, error };
};
export const addCategory = (category, setSubmitting, resetForm) => {
  return { type: ADD_CATEGORY, category, setSubmitting, resetForm };
};
export const addCategorySuccess = (category) => {
  return { type: ADD_CATEGORY_SUCCESS, category };
};
export const editCategory = (selectedCategory) => {
  return { type: EDIT_CATEGORY, selectedCategory };
};
export const editCategorySuccess = (category) => {
  return { type: EDIT_CATEGORY_SUCCESS, category };
};

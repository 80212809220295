const info = {
  shop: {
    name: process.env.REACT_APP_SHOP_NAME || '',
    address: process.env.REACT_APP_ADDRESS || '',
    telNo: process.env.REACT_APP_TEL_NO || '',
  },
  user: {
    userName: 'User',
  },
};

export default info;

export const SET_ITEM_BARCODE = 'genius/stock/SET_ITEM_BARCODE';
export const FETCH_DATA_FAIL = 'genius/stock/FETCH_DATA_FAIL';
export const FETCH_CURRENT_STOCK = 'genius/stock/FETCH_CURRENT_STOCK';
export const FETCH_CURRENT_STOCK_SUCCESS = 'genius/stock/FETCH_CURRENT_STOCK_SUCCESS';
export const ADD_STOCK = 'genius/stock/ADD_STOCK';
export const ADD_STOCK_SUCCESS = 'genius/stock/ADD_STOCK_SUCCESS';
export const FETCH_STOCK = 'genius/stock/FETCH_STOCK';
export const FETCH_STOCK_SUCCESS = 'genius/stock/FETCH_STOCK_SUCCESS';

const initialState = {
  loading: false,
  itemBarcode: '0',
  itemName: '',
  currentStockData: [],
  balancedStock: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_BARCODE:
      return { ...state, itemBarcode: action.itemBarcode, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false };
    case FETCH_CURRENT_STOCK:
      return { ...state, loading: true };
    case FETCH_CURRENT_STOCK_SUCCESS:
      return { ...state, currentStockData: action.currentStockData, loading: false };
    case FETCH_STOCK:
      return { ...state, loading: true };
    case FETCH_STOCK_SUCCESS:
      return { ...state, balancedStock: action.stock, loading: false };
    default:
      return state;
  }
};
export default reducer;

export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const setItemBarcode = (itemBarcode) => {
  return { type: SET_ITEM_BARCODE, itemBarcode };
};
export const fetchCurrentStock = () => {
  return { type: FETCH_CURRENT_STOCK };
};
export const fetchCurrentStockSuccess = (currentStockData) => {
  return { type: FETCH_CURRENT_STOCK_SUCCESS, currentStockData };
};
export const addStock = (balancedStock) => {
  return { type: ADD_STOCK, balancedStock };
};
export const addStockSuccess = (response) => {
  return { type: ADD_STOCK_SUCCESS, response };
};
export const fetchStock = () => {
  return { type: FETCH_STOCK };
};
export const fetchStockSuccess = (stock) => {
  return { type: FETCH_STOCK_SUCCESS, stock };
};

import commonEn from './Common/en.json';
import commonSin from './Common/si.json';

import itemEn from './Item/en.json';
import itemSin from './Item/si.json';

import drawerEn from './Drawer/en.json';
import drawerSin from './Drawer/si.json';

import customerEn from './Customer/en.json';
import customerSin from './Customer/si.json';

import dashboardEn from './Dashboard/en.json';
import dashboardSin from './Dashboard/si.json';

import supplierEn from './Supplier/en.json';
import supplierSin from './Supplier/si.json';

import userEn from './User/en.json';
import userSin from './User/si.json';

import grnEn from './Grn/en.json';
import grnSin from './Grn/si.json';

import stockBalanceEn from './StockBalance/en.json';
import stockBalanceSin from './StockBalance/si.json';

import reportsEn from './Reports/en.json';
import reportsSin from './Reports/si.json';

const en = {
  common: commonEn,
  item: itemEn,
  drawer: drawerEn,
  customer: customerEn,
  dashboard: dashboardEn,
  supplier: supplierEn,
  user: userEn,
  grn: grnEn,
  stock: stockBalanceEn,
  reports: reportsEn,
};

const si = {
  common: commonSin,
  item: itemSin,
  drawer: drawerSin,
  customer: customerSin,
  dashboard: dashboardSin,
  supplier: supplierSin,
  user: userSin,
  grn: grnSin,
  stock: stockBalanceSin,
  reports: reportsSin,
};

export { en, si };

import axios from 'axios';
import { getTokenSilently } from '../auth0';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3089/api/v1',
  crossDomain: true,
});

instance.interceptors.request.use(
  async (config) => {
    const token = await getTokenSilently();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default instance;

export const SET_SELECTED_FROM_DATE = 'genius/history/grnHistory/SET_SELECTED_FROM_DATE';
export const SET_SELECTED_TO_DATE = 'genius/history/grnHistory/SET_SELECTED_TO_DATE';
export const SET_GRN_NUMBER = 'genius/history/grnHistory/SET_GRN_NUMBER';
export const SET_SUPPLIER_ID = 'genius/history/grnHistory/SET_SUPPLIER_ID';
export const RESET_DATA = 'genius/history/grnHistory/RESET_DATA';
export const SET_SHOP_ID = 'genius/history/grnHistory/SET_SHOP_ID';
export const FETCH_DATA_WITH_GRN_NO = 'genius/history/grnHistory/FETCH_DATA_WITH_GRN_NO';

export const FETCH_DATA = 'genius/history/grnHistory/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/history/grnHistory/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/history/grnHistory/FETCH_DATA_FAIL';

export const FETCH_GRN_INVOICE_DATA = 'genius/history/grnHistory/FETCH_GRN_INVOICE_DATA';
export const FETCH_GRN_INVOICE_DATA_SUCCESS = 'genius/history/grnHistory/FETCH_GRN_INVOICE_DATA_SUCCESS';
export const FETCH_GRN_INVOICE_DATA_FAIL = 'genius/history/grnHistory/FETCH_GRN_INVOICE_DATA_FAIL';

const initialState = {
  loading: false,
  grnInvoice: [],
  grnHistory: [],
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  supplierId: '0',
  grnNumber: '0',
  shopId: '1111',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FROM_DATE:
      return { ...state, selectedFromDate: action.selectedFromDate, loading: false };
    case SET_SELECTED_TO_DATE:
      return { ...state, selectedToDate: action.selectedToDate, loading: false };
    case SET_GRN_NUMBER:
      return { ...state, grnNumber: action.grnNumber, loading: false };
    case SET_SUPPLIER_ID:
      return { ...state, supplierId: action.supplierId, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_WITH_GRN_NO:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, grnHistory: action.grnHistory, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, grnHistory: [] };
    case RESET_DATA:
      return {
        ...state,
        selectedFromDate: initialState.selectedFromDate,
        selectedToDate: initialState.selectedToDate,
        supplierId: initialState.supplierId,
        grnNumber: initialState.grnNumber,
        shopId: initialState.shopId,
      };
    case FETCH_GRN_INVOICE_DATA:
      return { ...state, loading: true };
    case FETCH_GRN_INVOICE_DATA_SUCCESS:
      return { ...state, grnInvoice: action.grnInvoice, loading: false };
    case FETCH_GRN_INVOICE_DATA_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default reducer;

export const setFromDate = (selectedFromDate) => {
  return { type: SET_SELECTED_FROM_DATE, selectedFromDate };
};
export const setToDate = (selectedToDate) => {
  return { type: SET_SELECTED_TO_DATE, selectedToDate };
};
export const setSupplierId = (supplierId) => {
  return { type: SET_SUPPLIER_ID, supplierId };
};
export const setGrnNumber = (grnNumber) => {
  return { type: SET_GRN_NUMBER, grnNumber };
};
export const fetchData = (selectedFromDate, selectedToDate, supplierId, shopId) => {
  return {
    type: FETCH_DATA,
    selectedFromDate,
    selectedToDate,
    supplierId,
    shopId,
  };
};
export const fetchDataWithGrnNo = (grnNumber) => {
  return {
    type: FETCH_DATA_WITH_GRN_NO,
    grnNumber,
  };
};
export const fetchDataSuccess = (grnHistory) => {
  return { type: FETCH_DATA_SUCCESS, grnHistory };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const fetchGrnInvoiceData = (supplierId) => {
  return { type: FETCH_GRN_INVOICE_DATA, supplierId };
};
export const fetchGrnInvoiceDataSuccess = (grnInvoice) => {
  return { type: FETCH_GRN_INVOICE_DATA_SUCCESS, grnInvoice };
};
export const fetchGrnInvoiceDataFail = (err) => {
  return { type: FETCH_GRN_INVOICE_DATA_FAIL, err };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};
export const resetData = () => {
  return { type: RESET_DATA };
};

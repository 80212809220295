import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { FETCH_DATA, fetchDataSuccess, fetchDataFail } from '../../reducers/reports/voidInvoiceSummaryReducer';

function* fetchVoidInvoiceSummary({ selectedFromDate, selectedToDate, counterId, shopId }) {
  try {
    if (counterId && shopId) {
      const response = yield call(
        axios.get,
        `reports/voidinvoicesummary?fromDate=${selectedFromDate}&toDate=${selectedToDate}&counterId=${counterId}&shopId=${shopId}`,
      );
      if (response.data.success === true) {
        yield put(fetchDataSuccess(response.data.data));
      } else {
        yield put(fetchDataFail());
      }
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchVoidInvoiceSummarySaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchVoidInvoiceSummary);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

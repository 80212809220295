import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_CATEGORY_DATA,
  fetchCategoryDataSuccess,
  fetchDataFail,
  addCategorySuccess,
  fetchCategoryData,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  editCategorySuccess,
} from '../reducers/categoryReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* addCategoryData({ category, setSubmitting, resetForm }) {
  try {
    const response = yield call(axios.post, 'category', category);

    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(addCategorySuccess(response));
        yield put(setSnackNotifyMessage('Category Added Successfully !', 'success'));

        yield put(fetchCategoryData());
      } else {
        yield put(setSnackNotifyMessage('Error Adding The Category !', 'error'));

        setSubmitting(false);
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The Category !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Category !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* addCategoryDataSaga() {
  try {
    yield takeLatest(ADD_CATEGORY, addCategoryData);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Category !', 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchCategory() {
  try {
    const response = yield call(axios.get, 'category');
    if (response.data.data) {
      yield put(fetchCategoryDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchCategorySaga() {
  try {
    yield takeLatest(FETCH_CATEGORY_DATA, fetchCategory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* editCategory({ selectedCategory }) {
  const { categoryId } = selectedCategory;
  const updatedCategory = { ...selectedCategory };
  delete updatedCategory.categoryId;
  try {
    const response = yield call(axios.put, `category/${categoryId}`, updatedCategory);
    if (response) {
      if (response.data.success === true) {
        yield put(editCategorySuccess(response));
        yield put(setSnackNotifyMessage('Category Updated Successfully !', 'success'));

        yield put(fetchCategoryData());
      } else {
        yield put(setSnackNotifyMessage('Error Updating The Category !', 'error'));

        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Updating The Category !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Category !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* editCategorySaga() {
  try {
    yield takeLatest(EDIT_CATEGORY, editCategory);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Category !', 'error'));

    yield put(fetchDataFail(error));
  }
}

import { takeLatest, put, call, fork } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_DASHBOARD_DATA,
  fetchDataFail,
  fetchInsightsDataSuccess,
  fetchHighestSellingDataSuccess,
  fetchInvoiceDataSuccess,
  fetchChartDataSuccess,
  fetchShopsDataSuccess,
} from '../reducers/dashboardReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* fetchDashboardInsightsData() {
  try {
    const response = yield call(axios.get, 'dashboard/insights');
    if (response.data) {
      yield put(fetchInsightsDataSuccess(response.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));
    yield put(fetchDataFail(error));
  }
}

function* fetchShopsData() {
  try {
    const response = yield call(axios.get, 'dashboard/shopsinfo');
    if (response.data) {
      yield put(fetchShopsDataSuccess(response.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchHighestSellingItems() {
  try {
    const response = yield call(axios.get, 'dashboard/highestsellingitemstoday');
    if (response.data) {
      yield put(fetchHighestSellingDataSuccess(response.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchLastFiveInvoices() {
  try {
    const response = yield call(axios.get, 'dashboard/lastfiveinvoices');
    if (response.data) {
      yield put(fetchInvoiceDataSuccess(response.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));

    yield put(fetchDataFail(error));
  }
}
function* fetchChartData() {
  try {
    const response = yield call(axios.get, 'dashboard/chartdata');
    if (response.data) {
      yield put(fetchChartDataSuccess(response.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchDashboardData() {
  yield fork(fetchDashboardInsightsData);
  yield fork(fetchHighestSellingItems);
  yield fork(fetchLastFiveInvoices);
  yield fork(fetchChartData);
  yield fork(fetchShopsData);
}
// eslint-disable-next-line import/prefer-default-export
export function* fetchDashboardDataSaga() {
  try {
    yield takeLatest(FETCH_DASHBOARD_DATA, fetchDashboardData);
  } catch (error) {
    yield put(setSnackNotifyMessage(`We are sorry! There's an error in connection !`, 'error'));

    yield put(fetchDataFail(error));
  }
}

import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../../../config/axios';
import {
  FETCH_DATA,
  fetchDataFail,
  fetchDataSuccess,
  FETCH_INVOICE_DATA,
  fetchInvoiceDataFail,
  fetchInvoiceDataSuccess,
  FETCH_DATA_WITH_INVOICE_NO,
} from '../../reducers/History/invoiceHistoryReducer';

function* fetchInvoiceHistory({ selectedFromDate, selectedToDate, customerId, userId, shopId }) {
  try {
    const response = yield call(
      axios.get,
      `invoices?fromDate=${selectedFromDate}&toDate=${selectedToDate}&userId=${userId}&customerId=${customerId}&shopId=${shopId}`,
    );
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchInvoiceHistorySaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchInvoiceHistory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchDataInvoiceHistory({ invoiceNumber }) {
  try {
    const response = yield call(axios.get, `invoices/${invoiceNumber}`);
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchDataInvoiceHistorySaga() {
  try {
    yield takeLatest(FETCH_DATA_WITH_INVOICE_NO, fetchDataInvoiceHistory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchInvoiceHistoryInvoice({ invoiceId }) {
  try {
    const response = yield call(axios.get, `invoices/${invoiceId}`);
    if (response.data.success === true) {
      yield put(fetchInvoiceDataSuccess(response.data));
    } else {
      yield put(fetchInvoiceDataFail());
    }
  } catch (error) {
    yield put(fetchInvoiceDataFail(error));
  }
}

export function* fetchInvoiceHistoryInvoiceSaga() {
  try {
    yield takeLatest(FETCH_INVOICE_DATA, fetchInvoiceHistoryInvoice);
  } catch (error) {
    yield put(fetchInvoiceDataFail(error));
  }
}

import React from 'react';
import { Router } from '@reach/router';
import { useAuth0 } from './auth0';

import history from './utils/history';

import Loading from './Components/Loading/Loading';
// import AuthenticatedApp from './AuthenticatedApp';
import PrivateRoute from './Components/PrivateRoute';
import Login from './Containers/Login/Login';
// import { SnackbarProvider } from 'notistack';
import Layout from './Containers/Layout';
import WithSnackBarReducer from './utils/withSnackBarProvider';

const App = () => {
  const { loading } = useAuth0();

  const Dashboard = React.lazy(() => import('./Containers/Dashboard/Dashboard'));

  const History = React.lazy(() => import('./Containers/History/HistoryHome'));
  const GrnHistory = React.lazy(() => import('./Containers/History/GrnHistory/GrnHistory'));
  const InvoiceHistory = React.lazy(() => import('./Containers/History/InvoiceHistory/InvoiceHistory'));
  const ReturnToSupplier = React.lazy(() => import('./Containers/History/ReturnsToSupplierHistory/ReturnsToSupplier'));
  const GrnInvoice = React.lazy(() => import('./Containers/History/GrnHistory/ViewGrn'));
  const Invoice = React.lazy(() => import('./Containers/History/InvoiceHistory/ViewInvoice'));
  const SupplierReturnInvoice = React.lazy(() =>
    import('./Containers/History/ReturnsToSupplierHistory/ViewReturnInvoice'),
  );

  const Reports = React.lazy(() => import('./Containers/Reports/ReportsHome'));
  const Sales = React.lazy(() => import('./Containers/Reports/Sales/Sales'));
  const CurrentStock = React.lazy(() => import('./Containers/Reports/CurrentStock/CurrentStock'));
  const InvoiceSummary = React.lazy(() => import('./Containers/Reports/InvoiceSummary/InvoiceSummary'));
  const DayEndSummary = React.lazy(() => import('./Containers/Reports/DayEndSummary/DayEndSummary'));
  const CardPayment = React.lazy(() => import('./Containers/Reports/CardPayments/CardPayments'));
  const CashierWiseInvoiceSummary = React.lazy(() =>
    import('./Containers/Reports/CashierWiseInvoiceSummary/CashierWiseInvoiceSummary'),
  );
  const CustomerChequePayments = React.lazy(() => import('./Containers/Reports/CustomerChequePayments/CustomerCheque'));
  const CustomerFreeIssues = React.lazy(() => import('./Containers/Reports/CustomerFreeIssues/CustomerFreeIssues'));
  const CustomerOutstandingSummary = React.lazy(() =>
    import('./Containers/Reports/CustomerOutstandingSummary/CustomerOutstandingSummary'),
  );
  const DailySales = React.lazy(() => import('./Containers/Reports/DailySales/DailySales'));
  const StockMovement = React.lazy(() => import('./Containers/Reports/ItemStockMovement/StockMovement'));
  const ItemWiseProfit = React.lazy(() => import('./Containers/Reports/ItemWiseProfit/ItemWiseProfit'));
  const ItemPriceFluctuation = React.lazy(() =>
    import('./Containers/Reports/ItemWisePriceFluctutation/ItemWisePriceFluctuation'),
  );
  const MonthlyPurchase = React.lazy(() => import('./Containers/Reports/MonthlyPurchase/MonthlyPurchase'));
  const SupplierChequePayment = React.lazy(() =>
    import('./Containers/Reports/SupplierChequePayments/SupplierChequePayments'),
  );
  const SupplierFreeIssues = React.lazy(() => import('./Containers/Reports/SupplierFreeIssues/SupplierFreeIssues'));
  const SupplierWisePurchase = React.lazy(() =>
    import('./Containers/Reports/SupplierWisePurchase/SupplierWisePurchase'),
  );
  const CashierWiseInvoices = React.lazy(() => import('./Containers/Reports/CashierWiseInvoices/CashierWiseInvoices'));
  const CustomerFreeIssueSummary = React.lazy(() =>
    import('./Containers/Reports/CustomerFreeIssueSummary/CustomerFreeIssueSummary'),
  );
  const SupplierFreeIssueSummary = React.lazy(() =>
    import('./Containers/Reports/SupplierFreeIssueSummary/SupplierFreeIssueSummary'),
  );
  const FastMovingItems = React.lazy(() => import('./Containers/Reports/FastMovingItems/FastMovingItems'));
  const MobileWalletPayment = React.lazy(() => import('./Containers/Reports/MobileWalletPayment/MobileWalletPayment'));
  const SalesAssistantWiseIncentive = React.lazy(() =>
    import('./Containers/Reports/SalesAssistantWiseIncentive/SalesAssistantWiseIncentive'),
  );
  const VoidInvoiceSummary = React.lazy(() => import('./Containers/Reports/VoidInvoice/VoidInvoiceSummary'));

  if (loading) {
    return <Loading />;
  }
  return (
    <WithSnackBarReducer>
      <Router history={history}>
        <Login path="/login" />
        <Layout path="/">
          <PrivateRoute as={Dashboard} path="/" />
          <PrivateRoute as={History} path="/history" />
          <PrivateRoute as={GrnHistory} path="/history/grn_history" />
          <PrivateRoute as={InvoiceHistory} path="/history/invoice_history" />
          <PrivateRoute as={ReturnToSupplier} path="/history/return_to_supplier" />
          <PrivateRoute as={GrnInvoice} path="/history/grn_history/grn/:Id" />
          <PrivateRoute as={Invoice} path="/history/invoice_history/invoice/:invoiceId" />
          <PrivateRoute
            as={SupplierReturnInvoice}
            path="/history/supplier_return_history/supplier_return_invoice/:supplierReturnId"
          />

          <PrivateRoute as={Reports} path="/reports" />
          <PrivateRoute as={Sales} path="/reports/sales" />
          <PrivateRoute as={CurrentStock} path="/reports/current_stock" />
          <PrivateRoute as={InvoiceSummary} path="/reports/invoice_summary" />
          <PrivateRoute as={DayEndSummary} path="/reports/day_end_summary" />
          <PrivateRoute as={CardPayment} path="/reports/card_payments" />
          <PrivateRoute as={CashierWiseInvoiceSummary} path="/reports/cashier_invoice_summary" />
          <PrivateRoute as={CustomerChequePayments} path="/reports/customer_cheque_payments" />
          <PrivateRoute as={CustomerFreeIssues} path="/reports/customer_free_issues" />
          <PrivateRoute as={CustomerOutstandingSummary} path="/reports/customer_outstanding_summary" />
          <PrivateRoute as={DailySales} path="/reports/daily_sales" />
          <PrivateRoute as={StockMovement} path="/reports/stock_movement" />
          <PrivateRoute as={ItemWiseProfit} path="/reports/item_wise_profit" />
          <PrivateRoute as={ItemPriceFluctuation} path="/reports/item_price_fluctuation" />
          <PrivateRoute as={MonthlyPurchase} path="/reports/monthly_sales" />
          <PrivateRoute as={SupplierChequePayment} path="/reports/supplier_cheque_payments" />
          <PrivateRoute as={SupplierFreeIssues} path="/reports/supplier_free_issues" />
          <PrivateRoute as={SupplierWisePurchase} path="/reports/supplier_wise_purchase" />
          <PrivateRoute as={CashierWiseInvoices} path="/reports/cashier_wise_invoices" />
          <PrivateRoute as={CustomerFreeIssueSummary} path="/reports/customer_free_issue_summary" />
          <PrivateRoute as={SupplierFreeIssueSummary} path="/reports/supplier_free_issue_summary" />
          <PrivateRoute as={FastMovingItems} path="/reports/fast_moving_items" />
          <PrivateRoute as={MobileWalletPayment} path="/reports/mobile_wallet_payment" />
          <PrivateRoute as={SalesAssistantWiseIncentive} path="/reports/sales_assistant_wise_incentive" />
          <PrivateRoute as={VoidInvoiceSummary} path="/reports/void_invoice_summary" />
        </Layout>
      </Router>
    </WithSnackBarReducer>
  );
};

export default App;

import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#fff',
    color: '#43a047',
    border: '2px solid #43a047',
    boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
    borderRadius: '.25rem',
    marginBottom: '7rem',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: '7rem',
    },
  },
  error: {
    backgroundColor: '#d32f2f',
    color: '#fff',
    border: '2px solid #d32f2f',
    boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
    borderRadius: '.25rem',
    marginBottom: '7rem',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: '7rem',
    },
  },
  warning: {
    backgroundColor: '#fff',
    color: '#ffa000',
    border: '2px solid #ffa000',
    boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
    borderRadius: '.25rem',
    marginBottom: '7rem',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: '7rem',
    },
  },
  info: {
    backgroundColor: '#fff',
    color: '#2979ff',
    border: '2px solid #2979ff',
    boxShadow: '0 0.5rem 1rem 0 rgba(44, 51, 73, .1)',
    borderRadius: '.25rem',
    marginBottom: '7rem',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: '7rem',
    },
  },
  btnDanger: {
    color: '#fff',
    backgroundColor: '#d32f2f',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
    '&:active': {
      backgroundColor: '#d32f2f',
    },
  },
}));

const WithSnackBarProvider = ({ children }) => {
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickRefresh = (key) => () => {
    // notistackRef.current.closeSnackbar(key);
    window.location.reload(key);
  };
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={1}
      hideIconVariant={false}
      dense={false}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      ref={notistackRef}
      action={(key) => (
        <div>
          <Button className={classes.btnDanger} onClick={onClickRefresh(key)}>
            Refresh
          </Button>
          <Button className={classes.btnDanger} onClick={onClickDismiss(key)}>
            Dismiss
          </Button>
        </div>
      )}>
      {children}
    </SnackbarProvider>
  );
};
WithSnackBarProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default WithSnackBarProvider;

import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { FETCH_DATA, fetchDataSuccess, fetchDataFail } from '../../reducers/reports/currentStockReducer';

function* fetchCurrentStocks({ sortBy, qtyBy, orderBy, shopId }) {
  try {
    if (sortBy && qtyBy && orderBy && shopId) {
      const response = yield call(
        axios.get,
        `reports/currentstock?sortOrder=${sortBy}&qtyLimit=${qtyBy}&orderBy=${orderBy}&shopId=${shopId}`,
      );
      if (response.data.success === true) {
        yield put(fetchDataSuccess(response.data.data));
      } else {
        yield put(fetchDataFail());
      }
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchCurrentStocksSaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchCurrentStocks);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { si, en } from '../Locales';

const options = {
  interpolation: {
    escapeValue: false,
  },
  languages: ['en', 'si'],
  debug: process.env.NODE_ENV === 'development',
  lng: 'en',

  resources: {
    si,
    en,
  },

  fallbackLng: 'en',

  ns: ['common', 'item'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },

  detection: {
    order: ['localStorage', 'path', 'subdomain'],
    lookupLocalStorage: 'gsd_lang',
    caches: ['localStorage'],
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18n;

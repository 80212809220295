export const FETCH_SUPPLIER_DATA = 'genius/supplier/FETCH_SUPPLIER_DATA';
export const FETCH_SUPPLIER_DATA_SUCCESS = 'genius/supplier/FETCH_SUPPLIER_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/supplier/FETCH_DATA_FAIL';
export const SET_SUPPLIER_NAME = 'genius/supplier/SET_SUPPLIER_NAME';
export const ADD_SUPPLIER = 'genius/supplier/ADD_SUPPLIER';
export const ADD_SUPPLIER_SUCCESS = 'genius/supplier/ADD_SUPPLIER_SUCCESS';
export const FETCH_SUPPLIER_ACCOUNT_DATA = 'genius/supplier/FETCH_SUPPLIER_ACCOUNT_DATA';
export const FETCH_SUPPLIER_ACCOUNT_DATA_SUCCESS = 'genius/supplier/FETCH_SUPPLIER_ACCOUNT_DATA_SUCCESS';
export const EDIT_SUPPLIER = 'genius/supplier/EDIT_SUPPLIER';
export const EDIT_SUPPLIER_SUCCESS = 'genius/supplier/EDIT_SUPPLIER_SUCCESS';

const initialState = {
  loading: false,
  supplierData: [],
  supplierName: '',
  supplierAccountData: [],
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIER_DATA:
      return { ...state, loading: true };
    case FETCH_SUPPLIER_DATA_SUCCESS: {
      return { ...state, supplierData: action.supplierData, loading: false };
    }
    case FETCH_DATA_FAIL:
      return { ...state, loading: false };
    case SET_SUPPLIER_NAME:
      return { ...state, supplierName: action.supplierName };
    case FETCH_SUPPLIER_ACCOUNT_DATA:
      return { ...state, loading: true };
    case FETCH_SUPPLIER_ACCOUNT_DATA_SUCCESS:
      return { ...state, loading: false, supplierAccountData: action.supplierAccountData };
    default:
      return state;
  }
};
export default supplierReducer;

export const fetchSupplierData = () => {
  return { type: FETCH_SUPPLIER_DATA };
};
export const fetchSupplierDataSuccess = (supplierData) => {
  return { type: FETCH_SUPPLIER_DATA_SUCCESS, supplierData };
};
export const fetchDataFail = (error) => {
  return { type: FETCH_DATA_FAIL, error };
};
export const setSupplierName = (supplierName) => {
  return { type: SET_SUPPLIER_NAME, supplierName };
};
export const addSupplier = (supplier, setSubmitting, resetForm) => {
  return { type: ADD_SUPPLIER, supplier, setSubmitting, resetForm };
};
export const addSupplierSuccess = (supplier) => {
  return { type: ADD_SUPPLIER_SUCCESS, supplier };
};
export const fetchSupplierAccountData = (supplier) => {
  return { type: FETCH_SUPPLIER_ACCOUNT_DATA, supplier };
};
export const fetchSupplierAccountDataSuccess = (supplierAccountData) => {
  return { type: FETCH_SUPPLIER_ACCOUNT_DATA_SUCCESS, supplierAccountData };
};
export const editSupplier = (supplier) => {
  return { type: EDIT_SUPPLIER, supplier };
};
export const editSupplierSuccess = (supplier) => {
  return { type: EDIT_SUPPLIER_SUCCESS, supplier };
};

export const SET_ORDER_BY = 'genius/reports/currentStock/SET_ORDER_BY';
export const SET_QTY_BY = 'genius/reports/currentStock/SET_QTY_BY';
export const SET_SORT_BY = 'genius/reports/currentStock/SET_SORT_BY';
export const SET_SHOP_ID = 'genius/reports/currentStock/SET_SHOP_ID';

export const FETCH_DATA = 'genius/reports/currentStock/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/currentStock/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/currentStock/FETCH_DATA_FAIL';
export const RESET_DATA = 'genius/reports/currentStock/RESET_DATA';

const initialState = {
  loading: false,
  currentStock: [],
  qtyBy: 'withZero',
  sortBy: 'asc',
  shopId: '0',
  orderBy: 'itemName',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, currentStock: action.currentStock, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, currentStock: [] };
    case SET_ORDER_BY:
      return { ...state, orderBy: action.orderBy, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case SET_QTY_BY:
      return { ...state, qtyBy: action.qtyBy, loading: false };
    case SET_SORT_BY:
      return { ...state, sortBy: action.sortBy, loading: false };
    case RESET_DATA:
      return {
        ...state,
        qtyBy: initialState.qtyBy,
        sortBy: initialState.sortBy,
        orderBy: initialState.orderBy,
        shopId: initialState.shopId,
      };
    default:
      return state;
  }
};
export default reducer;

export const fetchData = (sortBy, qtyBy, orderBy, shopId) => {
  return { type: FETCH_DATA, sortBy, qtyBy, orderBy, shopId };
};
export const fetchDataSuccess = (currentStock) => {
  return { type: FETCH_DATA_SUCCESS, currentStock };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const setOrderBy = (orderBy) => {
  return { type: SET_ORDER_BY, orderBy };
};
export const setSortBy = (sortBy) => {
  return { type: SET_SORT_BY, sortBy };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};
export const setQtyBy = (qtyBy) => {
  return { type: SET_QTY_BY, qtyBy };
};
export const resetCurrentStock = () => {
  return { type: RESET_DATA };
};

import ReactGA from 'react-ga';
import { globalHistory } from '@reach/router';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

const setupGA = () => {
  ReactGA.initialize(trackingId);
  globalHistory.listen(({ location }) => {
    ReactGA.set({ page: location.pathname + location.search });
    ReactGA.send('pageview');
  });
};

export default setupGA;

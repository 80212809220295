export const FETCH_CUSTOMER_DATA = 'genius/customer/FETCH_CUSTOMER_DATA';
export const FETCH_CUSTOMER_TABLE_DATA_SUCCESS = 'genius/customer/FETCH_CUSTOMER_TABLE_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/customer/FETCH_DATA_FAIL';
export const SET_CUSTOMER_NAME = 'genius/customer/SET_CUSTOMER_NAME';
export const SET_CUSTOMER_CODE = 'genius/customer/SET_CUSTOMER_CODE';
export const SET_CUSTOMER_TEL_NO = 'genius/customer/SET_CUSTOMER_TEL_NO';
export const SET_TEL_NO = 'genius/customer/SET_TEL_NO';
export const FETCH_CUSTOMER_ACCOUNTS = 'genius/customer/FETCH_CUSTOMER_ACCOUNTS';
export const FETCH_CUSTOMER_ACCOUNTS_SUCCESS = 'genius/customer/FETCH_CUSTOMER_ACCOUNTS_SUCCESS';
export const SET_CUSTOMER = 'genius/customer/SET_CUSTOMER';
export const ADD_CUSTOMER = 'genius/customer/ADD_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS = 'genius/customer/ADD_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER = 'genius/customer/EDIT_CUSTOMER';
export const EDIT_CUSTOMER_SUCCESS = 'genius/customer/EDIT_CUSTOMER_SUCCESS';

const initialState = {
  loading: false,
  customerData: [],
  customerName: '',
  customerCode: '',
  customerTelNo: '',
  customerAccountData: [],
  customer: '',
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMER_TABLE_DATA_SUCCESS:
      return {
        ...state,
        customerData: action.customerData,
        loading: false,
      };
    case FETCH_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_CUSTOMER_NAME:
      return {
        ...state,
        customerName: action.customerName,
      };
    case SET_CUSTOMER_CODE:
      return { ...state, customerCode: action.customerCode };
    case SET_TEL_NO:
      return { ...state, customerTelNo: action.customerTelNo };
    case FETCH_CUSTOMER_ACCOUNTS:
      return { ...state, loading: true };
    case FETCH_CUSTOMER_ACCOUNTS_SUCCESS:
      return { ...state, customerAccountData: action.customerAccountData };
    case SET_CUSTOMER:
      return { ...state, customer: action.customer };

    default:
      return state;
  }
};

export default customerReducer;

export const fetchCustomerData = () => {
  return {
    type: FETCH_CUSTOMER_DATA,
  };
};
export const fetchCustomerTableDataSuccess = (customerData) => {
  return {
    type: FETCH_CUSTOMER_TABLE_DATA_SUCCESS,
    customerData,
  };
};
export const fetchDataFail = (err) => {
  return {
    type: FETCH_DATA_FAIL,
    err,
  };
};
export const setCustomerName = (customerName) => {
  return {
    type: SET_CUSTOMER_NAME,
    customerName,
  };
};
export const setCustomerCode = (customerCode) => {
  return {
    type: SET_CUSTOMER_CODE,
    customerCode,
  };
};
export const setCustomerTelNo = (customerTelNo) => {
  return {
    type: SET_TEL_NO,
    customerTelNo,
  };
};
export const fetchCustomerAccountsData = (customer) => {
  return {
    type: FETCH_CUSTOMER_ACCOUNTS,
    customer,
  };
};
export const fetchCustomerAccountsDataSuccess = (customerAccountData) => {
  return { type: FETCH_CUSTOMER_ACCOUNTS_SUCCESS, customerAccountData };
};
export const setCustomer = (customer) => {
  return { type: SET_CUSTOMER, customer };
};
export const addCustomer = (customer, setSubmitting, resetForm) => {
  return { type: ADD_CUSTOMER, customer, setSubmitting, resetForm };
};
export const addCustomerSuccess = (customer) => {
  return { type: ADD_CUSTOMER_SUCCESS, customer };
};
export const editCustomer = (customer) => {
  return { type: EDIT_CUSTOMER, customer };
};
export const editCustomerSuccess = (customer) => {
  return { type: EDIT_CUSTOMER_SUCCESS, customer };
};

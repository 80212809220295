export const ADD_ITEM_DETAILS_SUCCESS = 'genius/grn/ADD_ITEM_DETAILS_SUCCESS';
export const SET_ITEM_BARCODE = 'genius/grn/SET_ITEM_BARCODE';
export const FETCH_DATA_FAIL = 'genius/grn/FETCH_DATA_FAIL';
export const DELETE_ITEM = 'genius/grn/DELETE_ITEM';
export const SET_SUPPLIER_NAME = 'genius/grn/SET_SUPPLIER_NAME';
export const SET_SUPPLIER_INVOICE_NO = 'genius/grn/SET_SUPPLIER_INVOICE_NO';
export const ADD_CHEQUE_PAYMENTS_SUCCESS = 'genius/grn/ADD_CHEQUE_PAYMENTS_SUCCESS';
export const ADD_GRN = 'genius/grn/ADD_GRN';
export const ADD_GRN_SUCCESS = 'genius/grn/ADD_GRN_SUCCESS';
export const ADD_GRN_FAIL = 'genius/grn/ADD_GRN_FAIL';
export const SET_GRN_DATE = 'genius/grn/SET_GRN_DATE';
export const RESET_GRN = 'genius/grn/RESET_GRN';
export const ADD_DISCOUNTED_PRICE = 'genius/grn/ADD_DISCOUNTED_PRICE';

const initialState = {
  loading: false,
  itemDetails: [],
  barcode: '0',
  supplierName: '',
  supplierInvoiceNo: '',
  currentGrn: '',
  chequePayments: [],
  grnDate: new Date(),
  discountedPrice: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_FAIL:
      return { ...state, loading: false };
    case ADD_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        itemDetails: [
          ...state.itemDetails,
          {
            ...action.itemDetails,
            grnDetailItemNo: state.itemDetails.length + 1,
            lineTotalValue: action.itemDetails.grnDetailQty * action.itemDetails.grnDetailPurUnitPrice,
          },
        ],
        loading: false,
      };
    case SET_ITEM_BARCODE:
      return { ...state, barcode: action.barcode, loading: false };
    case DELETE_ITEM:
      return {
        ...state,
        itemDetails: [...state.itemDetails.slice(0, action.index), ...state.itemDetails.slice(action.index + 1)],
      };
    case SET_SUPPLIER_NAME:
      return { ...state, supplierName: action.supplierName };
    case SET_SUPPLIER_INVOICE_NO:
      return { ...state, supplierInvoiceNo: action.supplierInvoiceNo };
    case ADD_CHEQUE_PAYMENTS_SUCCESS:
      return { ...state, chequePayments: action.chequePayments, loading: false };
    case ADD_GRN:
      return { ...state, loading: true };
    case ADD_GRN_SUCCESS:
      return { ...state, currentGrn: action.currentGrn, loading: false };
    case ADD_GRN_FAIL:
      return { ...state, loading: false };
    case SET_GRN_DATE:
      return { ...state, grnDate: action.grnDate, loading: false };
    case RESET_GRN:
      return {
        ...state,
        loading: false,
        // supplierName: initialState.supplierName,
        supplierInvoiceNo: initialState.supplierInvoiceNo,
        currentGrn: initialState.currentGrn,
        barcode: initialState.barcode,
        grnDate: initialState.grnDate,
        chequePayments: initialState.chequePayments,
        itemDetails: initialState.itemDetails,
      };
    case ADD_DISCOUNTED_PRICE: {
      return { ...state, loading: false, discountedPrice: action.discountedPrice };
    }
    default:
      return state;
  }
};

export default reducer;

export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const addItemDetailsSuccess = (itemDetails) => {
  return { type: ADD_ITEM_DETAILS_SUCCESS, itemDetails };
};
export const setItemBarcode = (barcode) => {
  return { type: SET_ITEM_BARCODE, barcode };
};
export const deleteItem = (index) => {
  return { type: DELETE_ITEM, index };
};
export const setSupplierName = (supplierName) => {
  return { type: SET_SUPPLIER_NAME, supplierName };
};
export const setSupplierInvoiceNo = (supplierInvoiceNo) => {
  return { type: SET_SUPPLIER_INVOICE_NO, supplierInvoiceNo };
};
export const addChequePayments = (chequePayments) => {
  return { type: ADD_CHEQUE_PAYMENTS_SUCCESS, chequePayments };
};
export const addGrn = (currentGrn, setSubmitting, resetForm) => {
  return {
    type: ADD_GRN,
    currentGrn,
    setSubmitting,
    resetForm,
  };
};
export const addGrnSuccess = (currentGrn) => {
  return {
    type: ADD_GRN_SUCCESS,
    currentGrn,
  };
};
export const setGrnDate = (grnDate) => {
  return { type: SET_GRN_DATE, grnDate };
};
export const resetGrn = () => {
  return { type: RESET_GRN };
};
export const addDiscountedPrice = (discountedPrice) => {
  return { type: ADD_DISCOUNTED_PRICE, discountedPrice };
};

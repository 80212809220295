import { put, call, takeLatest } from 'redux-saga/effects';
import axios from '../../../config/axios';
import {
  fetchDataSuccess,
  FETCH_DATA,
  fetchDataFail,
  FETCH_SUP_RETURN_INVOICE_DATA,
  fetchSupplierReturnDataSuccess,
  fetchSupplierReturnDataFail,
} from '../../reducers/History/supplierReturnHistoryReducer';

function* fetchSupplierReturnHistory({ selectedDate, supplierId, shopId }) {
  try {
    const response = yield call(
      axios.get,
      `supplierreturns?selectedDate=${selectedDate}&supplierId=${supplierId}&shopId=${shopId}`,
    );
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchSupplierReturnHistorySaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchSupplierReturnHistory);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchSupplierReturnHistoryInvoice({ supplierReturnId, returnDate, returnSupId, returnUserId }) {
  try {
    const response = yield call(axios.get, `supplierreturns/${supplierReturnId}`);
    if (response.data.success === true) {
      yield put(
        fetchSupplierReturnDataSuccess([
          {
            supplierReturnDate: returnDate,
            supplierReturnSupId: returnSupId,
            supplierReturnUserId: returnUserId,
            ...response.data.data[0],
          },
        ]),
      );
    } else {
      yield put(fetchSupplierReturnDataFail());
    }
  } catch (error) {
    yield put(fetchSupplierReturnDataFail(error));
  }
}

export function* fetchSupplierReturnHistoryInvoiceSaga() {
  try {
    yield takeLatest(FETCH_SUP_RETURN_INVOICE_DATA, fetchSupplierReturnHistoryInvoice);
  } catch (error) {
    yield put(fetchSupplierReturnDataFail(error));
  }
}

export const FETCH_DATA = 'genius/reports/customerOutstandingSummary/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/customerOutstandingSummary/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/customerOutstandingSummary/FETCH_DATA_FAIL';

const initialState = {
  loading: false,
  customerOutstandingSummary: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, customerOutstandingSummary: action.customerOutstandingSummary, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, customerOutstandingSummary: [] };
    default:
      return state;
  }
};
export default reducer;

export const fetchData = () => {
  return { type: FETCH_DATA };
};
export const fetchDataSuccess = (customerOutstandingSummary) => {
  return { type: FETCH_DATA_SUCCESS, customerOutstandingSummary };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};

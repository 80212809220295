import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'typeface-open-sans';
import './index.css';
import App from './App';
import store from './store';
import { Auth0Provider } from './auth0';
import history from './utils/history';
import setupGA from './config/ga';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = (appState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const EnhancedApp = () => {
  setupGA();
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      audience={process.env.REACT_APP_API_AUDIENCE}
      onRedirectCallback={onRedirectCallback}>
      <Provider store={store()}>
        <App />
      </Provider>
    </Auth0Provider>
  );
};

ReactDOM.render(<EnhancedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_SUPPLIER_DATA,
  fetchSupplierDataSuccess,
  fetchDataFail,
  ADD_SUPPLIER,
  fetchSupplierData,
  addSupplierSuccess,
  FETCH_SUPPLIER_ACCOUNT_DATA,
  fetchSupplierAccountDataSuccess,
  EDIT_SUPPLIER,
  editSupplierSuccess,
} from '../reducers/supplierReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* addSupplier({ supplier, setSubmitting, resetForm }) {
  try {
    const response = yield call(axios.post, 'suppliers', supplier);
    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(addSupplierSuccess(response));
        yield put(setSnackNotifyMessage('Supplier Added Successfully !', 'success'));

        yield put(fetchSupplierData());
      } else {
        yield put(setSnackNotifyMessage('Supplier Code Already Exists !', 'error'));

        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The Supplier !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Supplier !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* addSupplierSaga() {
  try {
    yield takeLatest(ADD_SUPPLIER, addSupplier);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Supplier !', 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchSuppliersData() {
  try {
    const response = yield call(axios.get, 'suppliers');
    if (response.data.data) {
      yield put(fetchSupplierDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail);
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchSuppliersDataSaga() {
  try {
    yield takeLatest(FETCH_SUPPLIER_DATA, fetchSuppliersData);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* fetchSupplierAccountsData({ supplier }) {
  const { supplierId } = supplier;
  try {
    const response = yield call(axios.get, `supplieraccounts/${supplierId}`);
    if (response.data.data) {
      yield put(fetchSupplierAccountDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchSupplierAccountsDataSaga() {
  try {
    yield takeLatest(FETCH_SUPPLIER_ACCOUNT_DATA, fetchSupplierAccountsData);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* editSupplierData({ supplier }) {
  const { supplierId } = supplier;
  const updatedSupplier = { ...supplier };
  delete updatedSupplier.supplierId;
  try {
    const response = yield call(axios.put, `suppliers/${supplierId}`, updatedSupplier);
    if (response) {
      if (response.data.success === true) {
        yield put(editSupplierSuccess(response));
        yield put(setSnackNotifyMessage('Supplier Updated Successfully !', 'success'));

        yield put(fetchSupplierData());
      } else {
        yield put(setSnackNotifyMessage('Supplier Code Already Exists !', 'error'));

        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Updating The Supplier !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Supplier !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* editSupplierDataSaga() {
  try {
    yield takeLatest(EDIT_SUPPLIER, editSupplierData);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The Supplier !', 'error'));

    yield put(fetchDataFail(error));
  }
}

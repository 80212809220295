export const SET_DATE = 'genius/reports/dayEndSummary/SET_DATE';
export const SET_USER_ID = 'genius/reports/dayEndSummary/SET_USER_ID';
export const SET_COUNTER_ID = 'genius/reports/dayEndSummary/SET_COUNTER_ID';
export const FETCH_DATA = 'genius/reports/dayEndSummary/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/dayEndSummary/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/dayEndSummary/FETCH_DATA_FAIL';
export const RESET_DATA = 'genius/reports/dayEndSummary/RESET_DATA';

const initialState = {
  loading: false,
  date: new Date(),
  userId: '0',
  counterId: '1111',
  dayEndData: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, dayEndData: action.dayEndData };
    case SET_DATE:
      return { ...state, date: action.date, loading: false };
    case SET_USER_ID:
      return { ...state, userId: action.userId, loading: false };
    case SET_COUNTER_ID:
      return { ...state, counterId: action.counterId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, dayEndData: action.dayEndData, loading: false };
    case RESET_DATA:
      return { ...state, date: initialState.date, userId: initialState.userId, counterId: initialState.counterId };
    default:
      return state;
  }
};

export default reducer;

export const fetchDataFail = (dayEndData) => {
  return { type: FETCH_DATA_FAIL, dayEndData };
};
export const fetchData = (userId, counterId, date) => {
  return { type: FETCH_DATA, userId, counterId, date };
};
export const fetchDataSuccess = (dayEndData) => {
  return { type: FETCH_DATA_SUCCESS, dayEndData };
};
export const setDate = (date) => {
  return { type: SET_DATE, date };
};
export const setUserId = (userId) => {
  return { type: SET_USER_ID, userId };
};
export const setCounterId = (counterId) => {
  return { type: SET_COUNTER_ID, counterId };
};
export const resetDayEnd = () => {
  return { type: RESET_DATA };
};

import NotoSerifRegularWoff2 from '../../Fonts/NotoSerif/NotoSerifSinhala-Regular.woff2';
import NotoSerifBoldWoff2 from '../../Fonts/NotoSerif/NotoSerifSinhala-Bold.woff2';

export const notoSerifSinhalaRegular = {
  fontFamily: 'Noto Serif Sinhala',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Noto Serif Sinhala'),
    local('NotoSerifSinhala-Regular'),
    url(${NotoSerifRegularWoff2}) format('woff2')
  `,
  // unicodeRange: 'U+0D80-0DFF',
};

export const notoSerifSinhalaBold = {
  fontFamily: 'Noto Serif Sinhala',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Noto Serif Sinhala'),
    local('NotoSerifSinhala-Bold'),
    url(${NotoSerifBoldWoff2}) format('woff2')
  `,
  // unicodeRange: 'U+0D80-0DFF',
};

import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../config/axios';
import {
  FETCH_USER_DATA,
  fetchUserDataSuccess,
  fetchDataFail,
  ADD_USER,
  addUserSuccess,
  fetchUserData,
  RESET_PASSWORD,
  resetPasswordSuccess,
  EDIT_USER,
  editUserSuccess,
} from '../reducers/userReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* resetPassword({ user, setSubmitting, resetForm }) {
  const { userId } = user.user;
  const resetData = { ...user };
  delete resetData.user;
  try {
    const response = yield call(axios.post, `users/${userId}`, resetData);
    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(resetPasswordSuccess(response));
        yield put(setSnackNotifyMessage('Password Reset Successful !', 'success'));
      } else {
        yield put(setSnackNotifyMessage('Error Resetting The Password !', 'error'));

        setSubmitting(false);
      }
    } else {
      yield put(setSnackNotifyMessage('Error Resetting The Password !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Resetting The Password !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* resetPasswordSaga() {
  try {
    yield takeLatest(RESET_PASSWORD, resetPassword);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Resetting The Password !', 'error'));

    yield put(fetchDataFail(error));
  }
}

function* addUser({ user, setSubmitting, resetForm }) {
  try {
    const response = yield call(axios.post, 'users', user);
    if (response) {
      if (response.data.success === true) {
        setSubmitting(true);
        resetForm();
        yield put(addUserSuccess(response));
        yield put(setSnackNotifyMessage('User Added Successfully !', 'success'));

        yield put(fetchUserData());
      } else {
        yield put(setSnackNotifyMessage('Error Adding The User !', 'error'));

        setSubmitting(false);
      }
    } else {
      yield put(setSnackNotifyMessage('Error Adding The User !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The User !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* addUserSaga() {
  try {
    yield takeLatest(ADD_USER, addUser);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The User !', 'error'));

    yield put(fetchDataFail(error));
  }
}

function* fetchUsersData() {
  try {
    const response = yield call(axios.get, 'users');
    if (response.data.data) {
      yield put(fetchUserDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export function* fetchUsersDataSaga() {
  try {
    yield takeLatest(FETCH_USER_DATA, fetchUsersData);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

function* editUserData({ user }) {
  const { userId } = user;
  const updatedUser = { ...user };
  delete updatedUser.userId;
  try {
    const response = yield call(axios.put, `/users/${userId}`, updatedUser);
    if (response) {
      if (response.data.success === true) {
        yield put(editUserSuccess(response));
        yield put(setSnackNotifyMessage('User Updated Successfully !', 'success'));

        yield put(fetchUserData());
      } else {
        yield put(setSnackNotifyMessage('Error Updating The User !', 'error'));

        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Error Updating The User !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The User !', 'error'));

    yield put(fetchDataFail(error));
  }
}

export function* editUserDataSaga() {
  try {
    yield takeLatest(EDIT_USER, editUserData);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Updating The User !', 'error'));

    yield put(fetchDataFail(error));
  }
}

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

// eslint-disable-next-line no-underscore-dangle
let _initOptions;

const getAuth0Client = async () => {
  let client;

  if (!client) {
    client = await createAuth0Client(_initOptions);
  }
  return client;
  // eslint-disable-next-line no-async-promise-executor
  // return new Promise(async (resolve, reject) => {
  //   let client;
  //   if (!client) {
  //     try {
  //       client = await createAuth0Client(_initOptions);
  //       resolve(client);
  //     } catch (e) {
  //       reject(new Error('getAuth0Client Error', e));
  //     }
  //   }
  // });
};

export const getTokenSilently = async (...p) => {
  const client = await getAuth0Client();
  // eslint-disable-next-line no-return-await
  return await client.getTokenSilently(...p);
};

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      const auth0FromHook = await getAuth0Client();
      // const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuth = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuth);

      if (isAuthenticated) {
        const authenticatedUser = await auth0FromHook.getUser();
        setUser(authenticatedUser);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const authenticatedUser = await auth0Client.getUser();
    setUser(authenticatedUser);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const authenticatedUser = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(authenticatedUser);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}>
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onRedirectCallback: PropTypes.func.isRequired,
};

export const SET_SELECTED_FROM_DATE = 'genius/reports/mobileWalletPayment/SET_SELECTED_FROM_DATE';
export const SET_SELECTED_TO_DATE = 'genius/reports/mobileWalletPayment/SET_SELECTED_TO_DATE';
export const FETCH_DATA = 'genius/reports/mobileWalletPayment/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/mobileWalletPayment/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/mobileWalletPayment/FETCH_DATA_FAIL';
export const RESET_DATA = 'genius/reports/mobileWalletPayment/RESET_DATA';
export const SET_SHOP_ID = 'genius/reports/mobileWalletPayment/SET_SHOP_ID';

const initialState = {
  loading: false,
  mobileWalletPaymentData: [],
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  shopId: '1111',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FROM_DATE:
      return { ...state, selectedFromDate: action.selectedFromDate, loading: false };
    case SET_SELECTED_TO_DATE:
      return { ...state, selectedToDate: action.selectedToDate, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, mobileWalletPaymentData: action.mobileWalletPaymentData, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, mobileWalletPaymentData: [] };
    case RESET_DATA:
      return {
        ...state,
        selectedFromDate: initialState.selectedFromDate,
        selectedToDate: initialState.selectedToDate,
        shopId: initialState.shopId,
      };
    default:
      return state;
  }
};
export default reducer;

export const setFromDate = (selectedFromDate) => {
  return { type: SET_SELECTED_FROM_DATE, selectedFromDate };
};
export const setToDate = (selectedToDate) => {
  return { type: SET_SELECTED_TO_DATE, selectedToDate };
};
export const fetchData = (selectedFromDate, selectedToDate, shopId) => {
  return { type: FETCH_DATA, selectedFromDate, selectedToDate, shopId };
};
export const fetchDataSuccess = (mobileWalletPaymentData) => {
  return { type: FETCH_DATA_SUCCESS, mobileWalletPaymentData };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const resetData = () => {
  return { type: RESET_DATA };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { useAuth0 } from '../../auth0';

const PrivateRoute = ({ as: Comp, path, ...props }) => {
  const { isAuthenticated } = useAuth0();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return isAuthenticated ? <Comp {...props} path={path} /> : <Redirect to="login" noThrow />;
};

PrivateRoute.propTypes = {
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;

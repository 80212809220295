export const SET_SNACK_NOTIFICATION = 'genius/notification/SET_SNACK_NOTIFICATION';
export const RESET_NOTIFICATION = 'genius/notification/RESET_NOTIFICATION';

const initialState = {
  message: '',
  notifyType: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACK_NOTIFICATION:
      return { ...state, message: action.message, notifyType: action.notifyType };
    case RESET_NOTIFICATION:
      return { ...state, message: initialState.message, notifyType: initialState.notifyType };
    default:
      return state;
  }
};
export default reducer;

export const setSnackNotifyMessage = (message, notifyType) => {
  return { type: SET_SNACK_NOTIFICATION, message, notifyType };
};
export const resetSnack = () => {
  return { type: RESET_NOTIFICATION };
};

import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '../../auth0';
import info from '../../config/info';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    fontFamily: [
      '"Open Sans"',
      '"Noto Serif Sinhala"',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/featured/?shopping)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  login: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  brand: {
    height: '50%',
    padding: '15%',
  },
  btn: {
    height: '50%',
  },
  shop: {
    fontWeight: '800',
    fontSize: '1.2rem',
  },
  shop1: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '50px',

    [theme.breakpoints.between('xs', 'md')]: {
      margin: '30px',
    },
  },
  shopName: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '50px',
    '& p': {
      color: '#8f9bb3',
    },
    [theme.breakpoints.between('xs', 'md')]: {
      margin: '30px',
    },
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />

      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.login}>
          <div className={classes.brand}>
            <div className={classes.shop1}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </div>
            <div className={classes.shop1}>
              <Typography align="center" className={classes.shop}>
                Genius Soft PVT Ltd
              </Typography>
            </div>
            <div className={classes.shopName}>
              <Typography align="center" className={classes.shop}>
                {info.shop.name}
              </Typography>
            </div>
          </div>
          <div className={classes.btn}>
            <Button
              style={{ margin: '10px', width: '50%' }}
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => loginWithRedirect()}>
              Sign In
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export const FETCH_ITEM_DATA = 'genius/item/FETCH_ITEM_DATA';
export const FETCH_ITEM_DATA_SUCCESS = 'genius/item/FETCH_ITEM_DATA_SUCCESS';
export const FETCH_ITEM_FAIL = 'genius/item/FETCH_ITEM_FAIL';
export const SET_ITEM_NAME = 'genius/item/SET_ITEM_NAME';
export const SET_ITEM_BARCODE = 'genius/item/SET_ITEM_BARCODE';
export const SET_ITEM_CODE = 'genius/item/SET_ITEM_CODE';
export const SET_ITEM_RETAIL_PRICE = 'genius/item/SET_ITEM_RETAIL_PRICE';
export const ADD_ITEM = 'genius/item/ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'genius/item/ADD_ITEM_SUCCESS';
export const EDIT_ITEM = 'genius/item/EDIT_ITEM';
export const EDIT_ITEM_SUCCESS = 'genius/item/EDIT_ITEM_SUCCESS';
export const DELETE_ITEM = 'genius/item/DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'genius/item/DELETE_ITEM_SUCCESS';

const initialState = {
  itemData: [],
  loading: false,
  itemName: '',
  itemCode: '',
  itemBarcode: '',
  itemRetailPrice: '',
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEM_DATA:
      return { ...state, loading: true };
    case FETCH_ITEM_DATA_SUCCESS:
      return {
        ...state,
        itemData: action.itemData,
        loading: false,
      };
    case FETCH_ITEM_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_ITEM_NAME:
      return {
        ...state,
        itemName: action.itemName,
      };
    case SET_ITEM_BARCODE:
      return {
        ...state,
        itemBarcode: action.itemBarcode,
      };
    case SET_ITEM_CODE:
      return {
        ...state,
        itemCode: action.itemCode,
      };
    case SET_ITEM_RETAIL_PRICE:
      return {
        ...state,
        itemRetailPrice: action.itemRetailPrice,
      };

    default:
      return state;
  }
};

export default itemReducer;

export const fetchItemData = () => {
  return {
    type: FETCH_ITEM_DATA,
  };
};
export const fetItemDataSuccess = (itemData) => {
  return { type: FETCH_ITEM_DATA_SUCCESS, itemData };
};
export const fetchItemFail = (err) => {
  return { type: FETCH_ITEM_FAIL, err };
};
export const setItemName = (itemName) => {
  return { type: SET_ITEM_NAME, itemName };
};
export const setItemBarcode = (itemBarcode) => {
  return { type: SET_ITEM_BARCODE, itemBarcode };
};
export const setItemCode = (itemCode) => {
  return { type: SET_ITEM_CODE, itemCode };
};
export const setItemRetailPrice = (itemRetailPrice) => {
  return { type: SET_ITEM_RETAIL_PRICE, itemRetailPrice };
};

export const addItem = (item, setSubmitting, resetForm) => {
  return { type: ADD_ITEM, item, setSubmitting, resetForm };
};
export const addItemSuccess = (item) => {
  return { type: ADD_ITEM_SUCCESS, item };
};
export const editItem = (item) => {
  return { type: EDIT_ITEM, item };
};
export const editItemSuccess = (item) => {
  return { type: EDIT_ITEM_SUCCESS, item };
};
export const deleteItem = (itemCode) => {
  return { type: DELETE_ITEM, itemCode };
};
export const deleteItemSuccess = (item) => {
  return { type: DELETE_ITEM_SUCCESS, item };
};

export const FETCH_USER_DATA = 'genius/user/FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'genius/user/FETCH_USER_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/user/FETCH_DATA_FAIL';
export const SET_USER_NAME = 'genius/user/SET_USER_NAME';
export const SET_USER_NAME_SUCCESS = 'genius/user/SET_USER_NAME_SUCCESS';
export const ADD_USER = 'genius/user/ADD_USER';
export const ADD_USER_SUCCESS = 'genius/user/ADD_USER_SUCCESS';
export const RESET_PASSWORD = 'genius/user/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'genius/user/RESET_PASSWORD_SUCCESS';
export const EDIT_USER = 'genius/user/EDIT_USER';
export const EDIT_USER_SUCCESS = 'genius/user/EDIT_USER_SUCCESS';

const initialState = {
  loading: false,
  userData: [],
  userName: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      return { ...state, loading: true };
    case FETCH_USER_DATA_SUCCESS:
      return { ...state, userData: action.userData, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false };
    case SET_USER_NAME:
      return { ...state, userName: action.userName };

    default:
      return state;
  }
};
export default userReducer;

export const fetchUserData = () => {
  return { type: FETCH_USER_DATA };
};
export const fetchUserDataSuccess = (userData) => {
  return { type: FETCH_USER_DATA_SUCCESS, userData };
};
export const fetchDataFail = (error) => {
  return { type: FETCH_DATA_FAIL, error };
};
export const setUserName = (userName) => {
  return { type: SET_USER_NAME, userName };
};
export const addUser = (user, setSubmitting, resetForm) => {
  return { type: ADD_USER, user, setSubmitting, resetForm };
};
export const addUserSuccess = (user) => {
  return { type: ADD_USER_SUCCESS, user };
};
export const resetPassword = (user, setSubmitting, resetForm) => {
  return { type: RESET_PASSWORD, user, setSubmitting, resetForm };
};
export const resetPasswordSuccess = (user) => {
  return { type: RESET_PASSWORD_SUCCESS, user };
};
export const editUser = (user) => {
  return { type: EDIT_USER, user };
};
export const editUserSuccess = (user) => {
  return { type: EDIT_USER_SUCCESS, user };
};

export const FETCH_DASHBOARD_DATA = 'genius/dashboard/FETCH_DASHBOARD_DATA';
export const FETCH_INSIGHTS_DATA_SUCCESS = 'genius/dashboard/FETCH_INSIGHTS_DATA_SUCCESS';
export const FETCH_SHOPS_DATA_SUCCESS = 'genius/dashboard/FETCH_SHOPS_DATA_SUCCESS';

export const FETCH_CHART_DATA_SUCCESS = 'genius/dashboard/FETCH_CHART_DATA_SUCCESS';
export const FETCH_HIGHEST_SELLING_DATA_SUCCESS = 'genius/dashboard/FETCH_HIGHEST_SELLING_DATA_SUCCESS';
export const FETCH_INVOICE_DATA_SUCCESS = 'genius/dashboard/FETCH_INVOICE_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/dashboard/FETCH_DATA_FAIL';

const initialState = {
  highestSellingItems: [],
  lastInvoices: [],
  shopsInfo: [],

  supCheques1: [],
  supCheques2: [],
  supCheques3: [],
  invoices: 0,
  itemsShop1: [],
  itemsShop2: [],
  itemsShop3: [],
  sales: '0.00',
  chartData: {
    invoiceCount: [],
    invoiceSales: [],
    lastSixMonthsSales: [],
  },
  loading: false,
  error: '',
};
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSIGHTS_DATA_SUCCESS:
      return {
        ...state,
        invoices: action.data.todayInvoicedAmount,
        supCheques1: action.data.todayChequesDetails,
        supCheques2: action.data.todayChequesDetailsShop2,
        supCheques3: action.data.todayChequesDetailsShop3,
        itemsShop1: action.data.todayReorderDetails,
        itemsShop2: action.data.todayReorderDetailsShop2,
        itemsShop3: action.data.todayReorderDetailsShop3,
        sales: action.data.todayInvoicedSalesSum,
        loading: false,
      };
    case FETCH_HIGHEST_SELLING_DATA_SUCCESS:
      return {
        ...state,
        highestSellingItems: action.data,
        loading: false,
      };
    case FETCH_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        lastInvoices: action.data,
        loading: false,
      };
    case FETCH_SHOPS_DATA_SUCCESS:
      return {
        ...state,
        shopsInfo: action.data,
        loading: false,
      };
    case FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: action.chartData,
        loading: false,
      };
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

export const fetchDashboardData = () => {
  return { type: FETCH_DASHBOARD_DATA };
};

export const fetchInsightsDataSuccess = (data) => {
  return {
    type: FETCH_INSIGHTS_DATA_SUCCESS,
    data,
  };
};

export const fetchHighestSellingDataSuccess = (data) => {
  return {
    type: FETCH_HIGHEST_SELLING_DATA_SUCCESS,
    data,
  };
};

export const fetchInvoiceDataSuccess = (data) => {
  return {
    type: FETCH_INVOICE_DATA_SUCCESS,
    data,
  };
};

export const fetchShopsDataSuccess = (data) => {
  return {
    type: FETCH_SHOPS_DATA_SUCCESS,
    data,
  };
};

export const fetchChartDataSuccess = (chartData) => {
  return {
    type: FETCH_CHART_DATA_SUCCESS,
    chartData,
  };
};

export const fetchDataFail = (error) => {
  return {
    type: FETCH_DATA_FAIL,
    error,
  };
};

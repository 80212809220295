import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { FETCH_DATA, fetchDataSuccess, fetchDataFail } from '../../reducers/reports/customerChequePaymentsReducer';

function* fetchCustomerChequePayments({ selectedFromDate, selectedToDate, shopId }) {
  try {
    const response = yield call(
      axios.get,
      `reports/customercheckpayments?fromDate=${selectedFromDate}&toDate=${selectedToDate}&shopId=${shopId}`,
    );
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchCustomerChequePaymentsSaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchCustomerChequePayments);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

export const SET_SELECTED_FROM_DATE = 'genius/reports/sales/SET_SELECTED_FROM_DATE';
export const SET_SELECTED_TO_DATE = 'genius/reports/sales/SET_SELECTED_TO_DATE';
export const SET_ORDER_BY = 'genius/reports/sales/SET_ORDER_BY';
export const SET_SORT_ORDER = 'genius/reports/sales/SET_SORT_ORDER';
export const SET_USER_ID = 'genius/reports/sales/SET_USER_ID';
export const SET_CATEGORY_ID = 'genius/reports/sales/SET_CATEGORY_ID';
export const SET_ITEM_CODE = 'genius/reports/sales/SET_ITEM_CODE';
export const SET_COUNTER_ID = 'genius/reports/sales/SET_COUNTER_ID';
export const SET_SHOP_ID = 'genius/reports/sales/SET_SHOP_ID';

export const RESET_DATA = 'genius/reports/sales/RESET_DATA';

export const FETCH_DATA = 'genius/reports/sales/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'genius/reports/sales/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/reports/sales/FETCH_DATA_FAIL';

const initialState = {
  loading: false,
  sales: [],
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  orderBy: 'itemName',
  sortBy: 'asc',
  userId: '0',
  categoryId: '0',
  itemCode: '0',
  counterId: '1111',
  shopId: '1111',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FROM_DATE:
      return { ...state, selectedFromDate: action.selectedFromDate, loading: false };
    case SET_SELECTED_TO_DATE:
      return { ...state, selectedToDate: action.selectedToDate, loading: false };
    case SET_ORDER_BY:
      return { ...state, orderBy: action.orderBy, loading: false };
    case SET_SORT_ORDER:
      return { ...state, sortBy: action.sortBy, loading: false };
    case SET_USER_ID:
      return { ...state, userId: action.userId, loading: false };
    case SET_CATEGORY_ID:
      return { ...state, categoryId: action.categoryId, loading: false };
    case SET_ITEM_CODE:
      return { ...state, itemCode: action.itemCode, loading: false };
    case SET_COUNTER_ID:
      return { ...state, counterId: action.counterId, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, sales: action.sales, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, sales: [] };
    case RESET_DATA:
      return {
        ...state,
        selectedFromDate: initialState.selectedFromDate,
        selectedToDate: initialState.selectedToDate,
        orderBy: initialState.orderBy,
        sortBy: initialState.sortBy,
        userId: initialState.userId,
        categoryId: initialState.categoryId,
        itemCode: initialState.itemCode,
        counterId: initialState.counterId,
        shopId: initialState.shopId,
      };
    default:
      return state;
  }
};
export default reducer;

export const setFromDate = (selectedFromDate) => {
  return { type: SET_SELECTED_FROM_DATE, selectedFromDate };
};
export const setToDate = (selectedToDate) => {
  return { type: SET_SELECTED_TO_DATE, selectedToDate };
};
export const fetchData = (
  selectedFromDate,
  selectedToDate,
  userId,
  categoryId,
  counterId,
  shopId,
  itemCode,
  sortBy,
  orderBy,
) => {
  return {
    type: FETCH_DATA,
    selectedFromDate,
    selectedToDate,
    userId,
    categoryId,
    counterId,
    shopId,
    itemCode,
    sortBy,
    orderBy,
  };
};
export const fetchDataSuccess = (sales) => {
  return { type: FETCH_DATA_SUCCESS, sales };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const setOrderBy = (orderBy) => {
  return { type: SET_ORDER_BY, orderBy };
};
export const setSortOrder = (sortBy) => {
  return { type: SET_SORT_ORDER, sortBy };
};
export const setUserId = (userId) => {
  return { type: SET_USER_ID, userId };
};
export const setCategoryId = (categoryId) => {
  return { type: SET_CATEGORY_ID, categoryId };
};
export const setItemCode = (itemCode) => {
  return { type: SET_ITEM_CODE, itemCode };
};
export const setCounterId = (counterId) => {
  return { type: SET_COUNTER_ID, counterId };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};
export const resetSales = () => {
  return { type: RESET_DATA };
};

import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { FETCH_DATA, fetchDataSuccess, fetchDataFail } from '../../reducers/reports/mobileWalletPaymentReducer';

function* fetchMobileWalletPayment({ selectedFromDate, selectedToDate, shopId }) {
  try {
    const response = yield call(
      axios.get,
      `reports/mobilewalletpayment?fromDate=${selectedFromDate}&toDate=${selectedToDate}&shopId=${shopId}`,
    );
    if (response.data.success === true) {
      yield put(fetchDataSuccess(response.data.data));
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchMobileWalletPaymentSaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchMobileWalletPayment);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { Decimal } from 'decimal.js';
import axios from '../../config/axios';
import { addGrnSuccess, ADD_GRN, fetchDataFail, resetGrn } from '../reducers/grnReducer';
import { setSnackNotifyMessage } from '../reducers/notificationReducer';

function* addGrnData({ currentGrn, setSubmitting, resetForm }) {
  const paidAmount = currentGrn.payment.map((pay) => pay.amount).reduce((acc, pivo) => new Decimal(acc).plus(pivo), 0);
  const total = currentGrn.items.map((totals) => totals.lineTotalValue).reduce((acc, pivo) => acc + pivo, 0);
  const discountedPrice = total - total * (currentGrn.grnDiscount / 100);

  try {
    if (parseInt(paidAmount, 0) <= discountedPrice) {
      const response = yield call(axios.post, 'grn', currentGrn);
      if (response.data.success === true) {
        yield put(resetGrn());
        yield put(addGrnSuccess(response.data.current));
        yield put(setSnackNotifyMessage('Grn Added Successfully !', 'success'));

        setSubmitting(true);

        resetForm();
      } else {
        yield put(setSnackNotifyMessage('Supplier Invoice Number Already Exists !', 'error'));

        setSubmitting(false);
        yield put(fetchDataFail());
      }
    } else {
      yield put(setSnackNotifyMessage('Paid Amount Can Not Be Higher Than Discounted Total !', 'error'));

      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Grn !', 'error'));

    yield put(fetchDataFail(error));
  }
}
// eslint-disable-next-line import/prefer-default-export
export function* addGrnDataSaga() {
  try {
    yield takeLatest(ADD_GRN, addGrnData);
  } catch (error) {
    yield put(setSnackNotifyMessage('Error Adding The Grn !', 'error'));

    yield put(fetchDataFail(error));
  }
}
